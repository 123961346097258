import CustomerRoutes from "../apiRoutes/CustomerRoutes";
import { MorocotasRoutes } from "../apiRoutes/apiMorocotas";
import { GET, POST } from "./ConsumeApiController";

export async function deposit(currency_id,date,amount,account_number_id,bank_id,pin='1234',observations='Depósito desde app móvil qud',reference='')
{
    var params = [
        {name:'currency_id',value:currency_id},
        {name:'create_real_date',value:date},
        {name:'amount',value:amount},
        {name:'account_number_id',value:account_number_id},
        {name:'bank_id',value:bank_id},
        {name:'gateway_id',value:'WEB'},
        {name:'observations',value:observations},
        {name:'reference',value:reference},
        {name:'transaction_type_id',value:6},
        //{name:'pin',value:'1234'},
    ];

    let response = await POST(CustomerRoutes.Wallet.Deposit,params);
    return response;

}

export async function depositWithoutLogin(sender,currency_id,date,amount,account_number_id,bank_id,pin='1234',observations='Depósito desde app móvil qud',reference='')
{
    var params = [
        {name:'sender',value:sender},
        {name:'currency_id',value:currency_id},
        {name:'create_real_date',value:date},
        {name:'amount',value:amount},
        {name:'account_number_id',value:account_number_id},
        {name:'bank_id',value:bank_id},
        {name:'gateway_id',value:'WEB'},
        {name:'observations',value:observations},
        {name:'reference',value:reference},
        {name:'transaction_type_id',value:6},
        //{name:'pin',value:'1234'},
    ];

    let response = await POST(CustomerRoutes.Wallet.DepositWithoutLogin,params);
    return response;

}


export async function payWithoutPin(amount,to,observations)
{
    var params = [
        {name:'currency_id',value:2},
        {name:'gateway_id',value:'WEB'},
        {name:'amount',value:amount},
        {name:'to',value:to},
        {name:'observations',value:observations},
    ];

    let response = await POST(CustomerRoutes.Wallet.PayWithoutPin,params);
    return response;
}

export async function payWithoutLogin(amount,to,observations,sender,eventUUID)
{
    var params = [
        {name:'currency_id',value:2},
        {name:'gateway_id',value:'WEB'},
        {name:'amount',value:amount},
        {name:'to',value:to},
        {name:'observations',value:observations},
        {name:'sender',value:sender},
        {name:'eventUUID',value:eventUUID},
    ];

    let response = await POST(CustomerRoutes.Wallet.PayWithoutLogin,params);
    return response;
}

export function GetAppToken()
{

}