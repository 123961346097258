import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faStop, faThList, faTicket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';

import { imagesURL } from '../../../apiRoutes/apiBase';
import DonwloadableQR from './downloadableQR';
import { getAll } from '../../../controllers/customer/ReservationsController';



//export default class TablaProductos extends Component
export default function ReservationsDataTable(props)
{
    const [reservations,SetReservations] = useState(props.reservations);
    const [reservation,setReservation] = useState(null);

    const [open,setOpen] = useState(false);


    const handleGetReservations = async () => {
        setOpen(false);
        let response = await getAll();
        if(response.success === true)
        {
            SetReservations(response.data);
        }
        
    }

    const handleOpenQR = (_reservation) => {
        setReservation(_reservation);
        setOpen(true);
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:100,height:100}} src={imagesURL + 'empresas/' + reservations[dataIndex].merchant_image}/>
                        </div>
                    );
                },
                filter:false
            }
        },
        {
            name : 'merchant',
            label : 'Comercio',
            options : 
            {
                /*customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography><strong style={{fontSize:17}}>{reservations[dataIndex].title}</strong></Typography>
                            <Typography variant='body1'>{reservations[dataIndex].description}</Typography>
                        </div>
                    );
                },*/
                filter:false,
                searchable:true
            }
        },
        {
            name : 'people',
            label : 'Personas',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'observations',
            label : 'Observaciones',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'date',
            label : 'Fecha',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'time',
            label : 'Hora',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : '',
            label : 'Código QR',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography
                            sx={{'&:hover':{cursor:'pointer'}}} color={'link.main'}
                            onClick={()=>handleOpenQR(reservations[dataIndex])}
                        >
                            Canjear
                        </Typography>
                    );
                },
                filter:false
            }
        },   
        {
            name : 'status',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Aprobado'
                    ? <Chip label='Disponible' color='success'/>
                    : <Chip label='Inactivo' color='error' />
                )            
            }
        },
        
    ];



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <>
            <MUIDataTable   
                title={"Listado de Reservaciones"}
                data={reservations}
                columns={columns}
                options={options}
            />
            {
                reservation !== null &&
                <DonwloadableQR open={open} reservation={reservation} handleClose={()=> handleGetReservations()}/>
            }
        </>
    )
}