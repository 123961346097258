import WebsiteRoutes from "../../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function review_create(type,toUUID,score,comment)
{
    const params = [
        {name:'type',value:type},
        {name:'toUUID',value:toUUID},
        {name:'score',value:score},
        {name:'comment',value:comment}
    ];

    const response = await POST(WebsiteRoutes.Reviews.Create,params);
    return response;
}