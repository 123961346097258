import { Avatar, Grid, Paper, Typography } from "@mui/material";

/**
 * hola
 * @param icon <Icon/>
 * @param title Es el titulo de la tarjeta
 */
export default function CardInfo(props)
{
    return(
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Paper>
                <Grid container style={{alignItems:'center'}}>
                    <Grid item>
                        <Avatar sx={{bgcolor:props.color}} style={{marginRight:10}}>
                            {props.icon}
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography style={{color:'#3c4257'}}>{props.title}</Typography>
                        <Typography variant='body1' style={{fontSize:20}}>{props.value}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}