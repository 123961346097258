import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import { WebSiteLayout } from '../layouts/Website/WebSiteLayout';
import Home from '../views/website/home';

//AUTH
import SelectUserType from '../views/auth/selectUserType';
import PersonSignUp from '../views/auth/signUp_person';
import SignIn from '../views/auth/signIn';
import ForgotPassword from '../views/auth/forgotPassword';

import { WebSiteLayout2 } from '../layouts/Website/WebsiteLayout2';
import Merchants from '../views/website/merchants';
import MerchantMenu from '../views/website/merchants/menu';
import MerchantProfile from '../views/website/merchants/profile';
import MerchantMenuProducts from '../views/website/merchants/menuProducts';
import MerchantSelect from '../views/website/merchants/select';
import CompanySignUp from '../views/auth/signUp_company';
import BusinessCard from '../views/website/merchants/businessCard';
import EmailVerification from '../views/auth/emailVerification';
import Privacy from '../views/legal/privacy';
import TermsAndConditions from '../views/legal/terms';
import DeleteUser from '../views/legal/deleteUser';
import Events from '../views/website/events';
import EventsDetails from '../views/website/events/detail';
import EventsTickets from '../views/website/events/tickets';
import EventsGastromedia from '../views/website/events/gastromedia';
import GastromediaAlly from '../views/website/allies/gastromedia';
import Hamburger from '../views/website/allies/tastyFest/hamburger';
import Usm from '../views/website/allies/usm';
import CompleteProfile from '../views/auth/CompleteProfile';
import { HomeLayout } from '../layouts/Website/home/HomeLayout';
import Search from '../views/website/search';
import TransportBooking from '../views/website/transporte/Booking';
import TransportBooking2 from '../views/website/transporte/Booking2';
import Ticket from '../views/client/events/ticket';
import Artemis from '../views/taxi/Artemis';
import Pagos from '../views/website/pagos';
import ForBusiness from '../views/website/ForBusiness';
import ForPeople from '../views/website/forPeople';
import MamaAzul from '../views/website/allies/mamaazul';
import JorgeSilvaCook from '../views/website/allies/exitart/JorgeSilva';
import SimpleLayout from '../layouts/simple';
import TeGustaElChisme from '../views/website/allies/tegustaelchisme';
import { EventsLayout } from '../layouts/Website/events/EventsLayout';
import FreeCoupon from '../views/website/merchants/freeCoupon';
import BoxPark from '../views/website/allies/boxpark';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ReactGA from "react-ga4";
import { usePageTracking } from '../hooks/usePageTracking';
import PrintQR from '../views/website/Print';
import UploadFile from '../views/website/UploadFile';
import MercadoPagoColombia from '../components/payments/MPColombia';



export default function WebSiteNavigation()
{
    usePageTracking()
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={HomeLayout} component={Home} />
            <AppRoute exact path='/mercadopagocolombia' layout={HomeLayout} component={MercadoPagoColombia} />
            <AppRoute exact path='/uploadfile' layout={UploadFile} component={Home} />
            <AppRoute exact path='/print' layout={HomeLayout} component={PrintQR} />
            <AppRoute exact path='/artemis' layout={WebSiteLayout} component={Artemis} />
            <AppRoute exact path='/pagos' layout={WebSiteLayout} component={Pagos} />
            <AppRoute exact path='/forbusiness' layout={HomeLayout} component={ForBusiness} />
            <AppRoute exact path='/forpeople' layout={HomeLayout} component={ForPeople} />
            <AppRoute exact path='/tegustaelchisme' layout={SimpleLayout} component={TeGustaElChisme} />

            {/**AUTH */}
            <AppRoute exact path='/auth/userType' layout={HomeLayout} component={SelectUserType} />
            <AppRoute path='/auth/personSignUp' layout={HomeLayout} component={PersonSignUp} />
            <AppRoute path='/auth/merchantSignUp' layout={HomeLayout} component={CompanySignUp} />
            <AppRoute path='/auth/signIn' layout={HomeLayout} component={SignIn} />
            <AppRoute path='/auth/forgotPassword' layout={WebSiteLayout} component={ForgotPassword} />
            <AppRoute path='/auth/verify/:email/:code' layout={WebSiteLayout} component={EmailVerification} />
            <AppRoute path='/auth/complete/profile/:uuid' layout={WebSiteLayout} component={CompleteProfile} />


            {/**Search */}
            <AppRoute exact path='/search/:category/:location?' layout={HomeLayout} component={Search} />
            

            {/**complete registration */}
            <AppRoute exact path='/auth/complete/profile/:uuid' layout={WebSiteLayout} component={ForgotPassword} />

            {/**smart cards */}
            <AppRoute exact path='/smartcards' layout={WebSiteLayout} component={ForgotPassword} />

            <AppRoute exact path='/merchants' layout={HomeLayout} component={Merchants} />
            <AppRoute exact path='/merchants/:uuid' layout={HomeLayout} component={MerchantSelect} />
            <AppRoute exact path='/merchants/:uuid/menu' layout={HomeLayout} component={MerchantMenu} />
            <AppRoute exact path='/merchants/:uuid/menu/:category_uuid' layout={HomeLayout} component={MerchantMenuProducts} />
            <AppRoute exact path='/merchants/:uuid/profile' layout={HomeLayout} component={MerchantProfile} />
            <AppRoute exact path='/freecoupon/:uuid' layout={HomeLayout} component={FreeCoupon} />

            <AppRoute exact path='/eventos' layout={EventsLayout} component={Events} />
            <AppRoute exact path='/eventos/:id' layout={WebSiteLayout2} component={EventsDetails} />
            <AppRoute exact path='/eventos/:id/tickets' layout={WebSiteLayout2} component={EventsTickets} />
            <AppRoute exact path='/eventos/:id/tastyfest/:producto_evento_uuid' layout={WebSiteLayout2} component={EventsGastromedia} />
            <AppRoute exact path='/gastromedia' layout={WebSiteLayout2} component={GastromediaAlly} />
            <AppRoute exact path='/tastyfest/:id' layout={WebSiteLayout2} component={EventsGastromedia} />
            <AppRoute exact path='/ticketing/:uuid' layout={WebSiteLayout2} component={Ticket} />

            

            {/**Allies */}
            <AppRoute exact path='/boxpark' layout={WebSiteLayout2} component={BoxPark} />
            <AppRoute exact path='/tastyfest' layout={WebSiteLayout2} component={Hamburger} />
            <AppRoute exact path='/usm' layout={WebSiteLayout2} component={Usm} />
            <AppRoute exact path='/mamaazul' layout={HomeLayout} component={MamaAzul} />
            <AppRoute exact path='/Asa2workshop' layout={HomeLayout} component={JorgeSilvaCook} />


            {/**Business Cards */}
            <AppRoute exact path='/businesscard/:uuid' layout={WebSiteLayout2} component={BusinessCard} />

            {/**Legal */}
            <AppRoute exact path='/privacy' layout={WebSiteLayout2} component={Privacy} />
            <AppRoute exact path='/termsandconditions' layout={WebSiteLayout2} component={TermsAndConditions} />
            <AppRoute exact path='/deleteUser' layout={WebSiteLayout2} component={DeleteUser} />

            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect  to="/"/>
            </Route>
        </Switch>
    )
}
