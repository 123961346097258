import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import CustomerRoutes from "../../apiRoutes/CustomerRoutes";
import WebsiteRoutes from "../../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function GetAllEvents()
{
    let response = await GET(WebsiteRoutes.Eventos.GetAll);
    return response;
}

export async function GetEventByID(id)
{
    let response = await GET(WebsiteRoutes.Eventos.GetEventByID+id);
    return response;
}

export async function BuyTickets(
    id_usuario,id_comercio,cliente,monto,comision,detalles,pagos=[],automatedPayment,
    holdToken,selectedTickets,eventKey,
    moneda='USD',tipo_pedido=1)
{
    let response = await POST_JSON(
        WebsiteRoutes.Eventos.Tickets.Buy,
        {
            id_usuario,
            id_comercio,
            cliente,
            monto,
            comision,
            moneda,
            detalles,
            pagos,
            automatedPayment,
            tipo_pedido,
            holdToken,
            selectedTickets,
            eventKey
        }
    );
    return response;
}


export async function SendRate(toUUID,score,comment,questions)
{
    let response = await POST_JSON(
        WebsiteRoutes.Rate.SendRate,
        {
            type:'product',
            toUUID,
            score,
            comment,
            questions
        }
    );
    return response;
}


export async function getGastromediaEvent(id)
{
    const response = await GET(WebsiteRoutes.Eventos.Gastromedia.GetEvento+id);
    return response;
}

export async function getSponsors(id)
{
    const response = await GET(WebsiteRoutes.Eventos.Sponsors.getAllbyEventiID+id);
    return response;
}


export async function getTicket(uuid)
{
    const response = await GET(CustomerRoutes.Events.Tickets.GetTicket+uuid);
    return response;
}

export async function getTicketList(){
    const response = await GET(CustomerRoutes.Events.Tickets.GetList);
    return response;
}