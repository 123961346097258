import { url } from "./apiBase";


const AuthRoutes = {
    personSignUp: url + 'register2',
    companySingUp: url + 'companysignup',
    signIn: url + 'login',
    signIn2: url + 'login2',
    verify : url + 'register/email_verification/', //completar con email/code
    changePassword : url + 'passwordreset/changePassword',
    changePasswordWithToken: url + 'passwordReset/changePasswordWithToken',
    requestCodeToResetPassword: url+'passwordreset/requestcode',//To request code to reset password
    verifyRequestedCode : url + 'passwordreset/verifycode',
    changePasswordLogin : url + 'perfil/changePasswordLogin',
    toCompleteProfile : url + 'register/complete/profile/', //completar con el uuid del usuario
    completeProfile : url + 'register/complete/profile/',
    /**
     * CompanySignup : url + 'companysignup',
     */
};


export default AuthRoutes;