import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Container, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import t1 from '../../assets/images/t10.png';
import s1 from '../../assets/images/s2.png';
import s2 from '../../assets/images/s7.png';

import icon_list from '../../assets/images/icon_list.png';
import icon_searcher from '../../assets/images/icon_searcher.png';
import icon_vcard from '../../assets/images/icon_vcard.png';
import icon_landingpage from '../../assets/images/icon_landingpage.png';
import o1 from '../../assets/images/website/marketplace.jpg';


import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ContactUs from "./contactUs";
import ContactUs2 from "./contactUs2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function ForBusiness()
{
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const theme = useTheme();


    const redirect = (route) => {
        history.push(route);
    }

    const presencia = [
        {
            title:{es:'Catálogo digital',en:'Digital catalogue/Menu'},
            description:
            {
                es:'Crea un catálogo online de tus productos o servicios para que tus clientes puedan acceder a él desde cualquier lugar y en cualquier momento.',
                en:'Create an online catalogue of your products or services so that your customers can access it from anywhere and at any time.'
            }
        },
        {
            title:{es:'Marketplace',en:'Marketplace'},
            description:
            {
                es:'Vende tus productos online a través de nuestro marketplace integrado, con pasarelas de pago nacionales e internacionales.',
                en:'Sell your products online through our integrated marketplace, with national and international payment gateways.'
            }
        },
        {
            title:{es:'Sistema de reseñas',en:'Reviews'},
            description:
            {
                es:'Permite que tus clientes dejen reseñas sobre tus productos o servicios, lo que te ayudará a generar confianza y aumentar tus ventas.',
                en:'Allow your customers to leave reviews about your products or services, which will help you build trust and increase your sales.'
            }
        },
        {
            title:{es:'Sistema de cupones',en:'Coupons'},
            description:
            {
                es:'Ofrece cupones y descuentos a tus clientes para fidelizarlos y aumentar tus ventas.',
                en:'Offer coupons and discounts to your customers to build loyalty and increase your sales.'
            }
        },
    ];

    const ventajas = [
        {
            src:icon_searcher,
            title:{es:'Listado de comercios',en:'Search engines'},
            description:
            {
                es:'Aparecerás en los buscadores y en el listado de comercios aliados de QUD',
                en:'You will appear in search engines and in the list of QUD partner businesses.'
            }
        },
        {
            src:icon_vcard,
            title:{es:'Tarjetas de presentación',en:'E-Business cards'},
            description:
            {
                es:'Podrás emitir tarjetas de presentación virtuales para ti y tu equipo',
                en:'You will be able to issue virtual business cards for yourself and your team'
            }
        },
        {
            src:icon_landingpage,
            title:{es:'Visibilidad',en:'Landing page'},
            description:
            {
                es:'Podrás mostrar información relevante de tu negocio a clientes potenciales',
                en:'You will be able to show relevant information about your business to potential customers'
            }
        },
    ];

    const gestion = [
        {
            title:{es:'Gestión de pedidos',en:'Order management'},
            description:
            {
                es:'Recibe y gestiona tus pedidos online de forma eficiente.',
                en:'Receive and manage your online orders efficiently.'
            }
        },
        {
            title:{es:'Gestión de clientes',en:'Customer Management'},
            description:
            {
                es:'Mantén una base de datos actualizada de tus clientes y segmenta tus envíos de email marketing.',
                en:'Keep an updated database of your customers and segment your email marketing campaigns.'
            }
        },
        {
            title:{es:'Gestión de eventos',en:'Event management'},
            description:
            {
                es:'Crea, organiza y promociona tus eventos online o presenciales.',
                en:'Create, organize and promote your online or in-person events.'
            }
        },
    ];

     /**
     *  title:{es:'',en:''},
        description:
        {
            es:'',
            en:''
        }
     */


    const princing = [
        {
            plan:'Starter',
            price: 15.00,
            anualPrice: 15.00,
            monthlyPrice:20.00,
            benefits:{
                es:[
                    'Landing page',
                    'Catálogo digital',
                    'Marketplace',
                    'Sistema de reseñas',
                    'Sistema de Cupones',
                    'Gestión de pedidos',
                    'Gestión de clientes',
                    'Gestión de reservaciones',
                    'Gestión de eventos',
                    'Tarjetas de presentación'
                ],
                en:[
                    'Landing page',
                    'Digital catalogue/menu',
                    'Marketplace',
                    'Reviews',
                    'Coupons',
                    'Order management',
                    'Customer Management',
                    'Reservation Management',
                    'Event management',
                    'E-Business cards'
                ]
            }
        },
        /*{
            plan:'Business',
            anualPrice: 18.00,
            monthlyPrice:23.00,
            benefits:[
                '+ Starter',
                'Analítica de productos',
                'Analítica de reseñas',
                'Gestión de reservaciones',
                'Email marketing',
            ]
        }*/
    ]

    return(
        <Box>
            <Box sx={{minHeight:'90vh',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <Container maxWidth='lg' sx={{paddingTop:{xs:20,lg:10}}}>
                    <Grid container spacing={3} alignItems={'center'}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <motion.div
                                initial={{ opacity: 0,translateY:200 }}
                                whileInView={{ opacity: 1,translateY:0 }}
                                viewport={{ once: false }}
                            >
                                <img
                                    src={t1}
                                    alt='cupones'
                                    style={{width:'100%',borderRadius:50}}
                                />
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4' fontSize={45} component={'h1'} color={'primary.main'} fontWeight={'bold'} gutterBottom>
                                {t('forBusiness.title')}
                            </Typography>
                            <Typography variant='h6' component={'p'} color={'#5A5A5A'} gutterBottom mt={5}>
                                {t('forBusiness.description')}
                            </Typography>
                            <Button variant='contained' sx={{mt:5}} onClick={()=>redirect('/auth/merchantSignUp')}>
                                <Typography variant='h6'>
                                    &nbsp; &nbsp; &nbsp; {t('helpers.joinNow')} &nbsp; &nbsp; &nbsp;
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{bgcolor:'primary.main',mt:7,mb:7}}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} mt={5} mb={5}>
                        <Typography variant="h4" fontWeight={'bold'} textAlign={'center'} color={'primary.contrastText'}>
                            {t('forBusiness.qudTools')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {/**Presencia online */}
            <Box>
                <Container maxWidth='lg' >
                    <Grid container spacing={3} alignItems={'center'}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4' fontSize={45} component={'h1'} color={'primary.main'} fontWeight={'bold'} gutterBottom>
                                {t('forBusiness.bootsOnline')}
                            </Typography>

                            <Box>
                                {
                                    presencia.map(item=>
                                        <Accordion elevation={0}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant='h6' component={'h1'} fontWeight={'bold'} color={'primary.main'} gutterBottom >
                                                    {i18n.language==='es'?item.title.es:item.title.en}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant='h6' component={'p'} color={'#5A5A5A'} gutterBottom >
                                                    {i18n.language==='es'?item.description.es:item.description.en}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>       
                                    ) 
                                }
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <motion.div
                                initial={{ opacity: 0,translateY:200 }}
                                whileInView={{ opacity: 1,translateY:0 }}
                                viewport={{ once: false }}
                            >
                                <img
                                    src={s1}
                                    alt='cupones'
                                    style={{width:'100%',borderRadius:50}}
                                />
                            </motion.div>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            {/**Ventajas */}
            <Box mt={10}>
                <Container maxWidth='lg'>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {
                                i18n.language === 'es'
                                ?
                                <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                                    Si ya tienes QUD<br/>
                                    puedes disponer de<br/> las siguientes
                                    ventajas
                                </Typography>
                                :
                                <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                                    If you already have<br/>
                                    a user in QUD<br/>
                                    you have the <br/>following advantages
                                </Typography>
                            }
                            
                        </Grid>
                            
                        {
                            ventajas.map(item =>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display={'flex'} flexDirection={'column'} alignItems={'center'} mt={10}>
                                    <Paper elevation={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',mt:-10}}>
                                                    <Box sx={{bgcolor:'primary.main',p:1,borderRadius:5}}>
                                                        <Avatar
                                                            variant="rounded"
                                                            alt="QUD"
                                                            src={item.src}
                                                            sx={{ width: 100, height: 100 }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography variant="h5" fontWeight={'bold'} textAlign={'center'}>
                                                    {i18n.language === 'es' ? item.title.es : item.title.en}
                                                </Typography>
                                                <Typography pl={3} pr={3} variant='h6' component={'p'} color={'#5A5A5A'} gutterBottom mt={5} textAlign={'center'}>
                                                    {i18n.language === 'es' ? item.description.es : item.description.en}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        }
                        

                        

                    </Grid>
                </Container>
            </Box>


            {/**Optimizar la gestión */}
            <Box sx={{mt:15,mb:15}}>
                <Container maxWidth='lg' >
                    <Grid container spacing={3} alignItems={'center'}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <motion.div
                                    initial={{ opacity: 0,translateY:200 }}
                                    whileInView={{ opacity: 1,translateY:0 }}
                                    viewport={{ once: false }}
                                >
                                    <img
                                        src={s2}
                                        alt='cupones'
                                        style={{width:'100%',borderRadius:50}}
                                    />
                                </motion.div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4' fontSize={45} component={'h1'} color={'primary.main'} fontWeight={'bold'} textAlign={'right'} gutterBottom>
                                {t('forBusiness.optimize')}
                            </Typography>
                            <Box>
                                {
                                    gestion.map(item=>
                                        <Accordion elevation={0}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography variant='h6' component={'h1'} fontWeight={'bold'} color={'primary.main'} gutterBottom >
                                                    {i18n.language === 'es' ? item.title.es : item.title.en}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant='h6' component={'p'} color={'#5A5A5A'} gutterBottom >
                                                {i18n.language === 'es' ? item.description.es : item.description.en}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>       
                                    ) 
                                }
                            </Box>
                        </Grid>

                        

                    </Grid>
                </Container>
            </Box>


            {/** 
            <Box mt={5}>
                <Container maxWidth='lg'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant='h5' component={'p'} color={'#5A5A5A'} gutterBottom textAlign={'center'}>
                                Todo esto, con una interfaz intuitiva y fácil de usar, a un precio accesible para empresas de todos los tamaños.
                            </Typography>
                            <Typography mt={5} variant='h5' component={'p'} color={'#5A5A5A'} gutterBottom textAlign={'center'}>
                                Con nuestras herramientas, puedes empezar a trabajar de inmediato sin necesidad de conocimientos técnicos.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>*/}

            {/**Pricing */}
            <Box
                sx={{
                    mb:10,
                    position:'relative'
                }}
            >
                
                    <Container maxWidth='lg'>
                        <Grid container spacing={3} justifyContent={'center'}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant='h4' fontSize={45} fontWeight={'bold'} color='primary.main' textAlign={'center'}>
                                    {i18n.language === 'es' ? 'Nuestros Planes' : 'Our Membership Plans'}
                                </Typography>
                            </Grid>
                            {
                                princing.length !== 0 &&
                                princing.map(item =>
                                    <Grid item xs={12} lg={4} xl={4}>
                                        <Paper elevation={4} sx={{height:'100%'}}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography variant="h6" fontWeight={'bold'} color={'link.main'}>
                                                        {item.plan}
                                                    </Typography>
                                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                                        <Typography variant="h4" fontWeight={'bold'}>{item.anualPrice} $</Typography>
                                                        <Typography variant="h6" fontWeight={'bold'} ml={1}>/{t('helpers.month')}*</Typography>
                                                    </Box>
                                                    <Typography fontWeight={'bold'} color={'cancel.dark'} mb={1}>
                                                        *{t('helpers.anualBilling')}
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {item.monthlyPrice} $ /{t('helpers.month')} {t('helpers.monthlyBilling')}
                                                    </Typography>
                                                    <Divider/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Typography variant="body1" fontWeight={'bold'}>
                                                        {t('helpers.includes')}
                                                    </Typography>
                                                    {
                                                        i18n.language === 'es'
                                                        ?
                                                        item.benefits.es.map(benefit=>
                                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',mt:1}}>
                                                            <FontAwesomeIcon color={theme.palette.success.main} icon={faCheckSquare}/>
                                                            <Typography variant="body1" fontWeight={'bold'} ml={1}>{benefit}</Typography>
                                                        </Box>
                                                        )
                                                        :
                                                        item.benefits.en.map(benefit=>
                                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',mt:1}}>
                                                                <FontAwesomeIcon color={theme.palette.success.main} icon={faCheckSquare}/>
                                                                <Typography variant="body1" fontWeight={'bold'} ml={1}>{benefit}</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Container>
            </Box>

            {/**Contactanos */}
            <ContactUs2
                title={t('contactUs.title')}
                description={t('contactUs.text')}
                buttonText={t('contactUs.buttonText')}
            />


        </Box>
    )
}