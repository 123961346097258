import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";


import ReactPlayer from 'react-player/youtube'
import QUDProgress from "../../../../components/progress";

import img_tegustaelchisme_ from '../../../../assets/images/publicidad/tegustaelchisme_2.png';

import GooglePlacesAutocomplete, {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';

export default function TeGustaElChisme()
{
    const [isPlaying,setIsPlaying] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const [value, setValue] = useState(null);

    useEffect(()=>{
        if(value !== null)
        {
            console.log('VALUE GOOGLE MAPS ===> ',value);
            geocodeByPlaceId(value.value.place_id)
            .then(results => getLatLng(results[0]) )
            .then(({lat,lng}) => console.log('LATITUD-LONGITUD ===> ',{lat,lng}) )
            .catch(error => console.error('ERROR ===> ',error))
        }
        
    },[value]);

    useEffect(()=>{
        setIsLoading(true);
        setTimeout(function(){
            setIsLoading(false);
            setIsPlaying(true);
        },3000);
    },[]);

    return(
        <Box
            sx={{
                minHeight:'100vh',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                backgroundImage: `url(${img_tegustaelchisme_})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <QUDProgress open={isLoading}/>
            <Container>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" textAlign={'center'} fontWeight={'bold'} mt={3}>
                            ¡Prepárate!<br/>La <strong>publicidad</strong> está a punto de evolucionar.
                        </Typography>
                    </Grid>

                    <Grid item lg={12} xl={12}>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE"
                            selectProps={{
                                value,
                                onChange: setValue,
                            }}
                            
                        /> 
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                        <ReactPlayer
                            url='https://www.youtube.com/watch?v=LXb3EKWsInQ'
                            loop
                            playing={isPlaying}
                            
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" textAlign={'center'}>
                            ¿Estás listo para algo completamente nuevo y emocionante?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            Si 
                            <Button variant="text" color="link" size="small" LinkComponent={'a'} href="https://www.instagram.com/tegustaelchisme_/" target="_blank">
                                <Typography variant='h5' fontWeight={'bold'}>@tegustaelchisme_</Typography>
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" textAlign={'center'} mt={-2}>
                        ¡Quédate atento!
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}