import { useReducer } from "react";
import { LoadingButton } from "@mui/lab";
import { Container, Divider, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { Add } from "../../../controllers/company/CouponsController";
import moment from "moment";
import ModalDialog from "../../../components/modalDialog";
import { useHistory } from "react-router-dom";


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    title: '',      errorTitle: false,
    amount:'',      errorAmount: false,
    description:'', errorDescription: false,
    receiver:'',    errorReceiver: false,
    startDate: new Date(),       errorStartDate: false,
    endDate:null,         errorEndDate: false,
    
    
    isLoading : false,

    open: false,
    titleR: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }


export default function CouponsAdd()
{
    const [state,setState] = useReducer(reducer,initialState);

    const {
        title, errorTitle, amount, errorAmount, description, errorDescription,
        receiver, errorReceiver, startDate, errorStartDate, 
        endDate, errorEndDate, isLoading, open, titleR, message, success
    } = state;

    const history = useHistory();


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleStartDateChange = (newValue) => {
        //return console.log(newValue)
        setState({field:'startDate',value:newValue});
    };

    const handleEndDateChange = (newValue) => {
        //return console.log(newValue)
        setState({field:'endDate',value:newValue});
    };

    const handleAddCoupon = async () => {
        setState({field:'isLoading',value:true});

        const start_date = moment(startDate).format('YYYY-MM-DD');
        let end_date = null;
        if(endDate !== null)
            end_date = moment(endDate).format('YYYY-MM-DD');

        let response = await Add(title,amount,description,receiver,start_date,end_date);
        if(response.success === true)
        {
            setState({field:'titleR',value:'Operacion Exitosa!'});
            setState({field:'message',value:response.message});
            setState({field:'success',value:true});
            setState({field:'open',value:true});
        }
        else
        {
            setState({field:'titleR',value:'Operacion Fallida!'});
            setState({field:'message',value:response.message});
            setState({field:'success',value:false});
            setState({field:'open',value:true});
        }
        console.log(response);
    }

    return(
        <Container spacing={3}>
            <CustomBreadcrumbs
                returnTo='/coupons'
                crumbs={[
                    {name:'Cupones',link:'/coupons'},
                    {name:'Emitir',link:'/coupons/add'}
                ]}
            />
            <SectionTitle title='Emitir cupón' />
            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Title */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='Título'
                                id='title'
                                name='title'
                                value= {title}
                                onChange= {onChange}
                                onBlur= {()=> title === '' ? setState({field:'errorTitle',value:true}) : setState({field:'errorTitle',value:false}) }
                                error= {errorTitle}
                                helperText={errorTitle && 'Debe ingresar el título del cupón'}
                            />  
                        </Grid>

                        {/**Amount */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='Monto en $ (opcional)'
                                id='amount'
                                name='amount'
                                value= {amount}
                                onChange= {onChange}
                                //onBlur= {()=> amount === '' ? setState({field:'errorAmount',value:true}) : setState({field:'errorAmount',value:false}) }
                                //error= {errorAmount}
                                //helperText={errorAmount && 'Debe ingresar el título'}
                            />
                        </Grid>

                        {/**Description */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='Descripción'
                                id='description'
                                name='description'
                                value= {description}
                                onChange= {onChange}
                                onBlur= {()=> description === '' ? setState({field:'errorDescription',value:true}) : setState({field:'errorDescription',value:false}) }
                                error= {errorDescription}
                                helperText={errorDescription && 'Debe ingresar la descripción del cupón'}
                            />
                        </Grid>

                        {/**Receiver */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='Correo Receptor'
                                id='receiver'
                                name='receiver'
                                value= {receiver}
                                onChange= {onChange}
                                onBlur= {()=> receiver === '' ? setState({field:'errorReceiver',value:true}) : setState({field:'errorReceiver',value:false}) }
                                error= {errorReceiver}
                                helperText={errorReceiver && 'Debe ingresar el correo del receptor'}
                            />
                        </Grid>

                        {/**Start Date */}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <LocalizationProvider dateAdapter={ AdapterMoment }>
                                <MobileDatePicker
                                    label='Fecha de inicio'
                                    inputFormat="DD/MM/YYYY"
                                    minDate={new Date()}
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/**End date */}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <LocalizationProvider dateAdapter={ AdapterMoment }>
                                <MobileDatePicker
                                    label='Fecha de Fin'
                                    inputFormat="DD/MM/YYYY"
                                    minDate={new Date()}
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton variant="contained" fullWidth
                                loading={isLoading}
                                onClick={()=>handleAddCoupon()}
                            >
                                <Typography>Emitir cupón </Typography>
                            </LoadingButton>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>

            <ModalDialog
                open={open}
                title={titleR}
                msg={message}

                close='Cerrar'
                handleClose={()=>success === true ? history.push('/coupons') : setState({field:'open',value:'false'})}
                
                action='Aceptar'
                handleAction={()=>success === true ? history.push('/coupons') : setState({field:'open',value:'false'})}
            />
        </Container>
    )
}