import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

export default function HetlmetTag(props)
{
    return(
        <Helmet helmetData={helmetData}>
            <title>{props.title}</title>
            <meta name="description" content={props.pageDescription} />
        </Helmet>
    )
}