import { forwardRef, useRef, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, useTheme } from "@mui/material";
import QRCode from "react-qr-code";
import { domain, imagesURL } from "../../../apiRoutes/apiBase";

import { toPng } from 'html-to-image';
import { Typography } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function DonwloadableQR(props)
{
    //const [open, setOpen] = useState(props.open)
    const qrCodeRef = useRef(null);

    const theme = useTheme()
    
    const downloadQRCode = async() => {

        if (qrCodeRef.current) {
          toPng(qrCodeRef.current,{width:450,height:450})
            .then(function (dataUrl) {
              const link = document.createElement('a');
              link.href = dataUrl;
              link.download = props.reservation.uuid+'.png'; // Nombre del archivo a descargar
              link.click();
            });
        }
    };

   
    
    return(
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            
            onClose={props.handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth='xs'
            PaperProps={{
                sx:{
                    bgcolor:'primary.main',
                    padding:2
                }
            }}
        >
            {
                props.reservation !== undefined &&
                <>
                    {/*<DialogTitle>{"Código QR de "+props.reservation.title}</DialogTitle>*/}
                    <DialogContent sx={{background:'#FFF',overflowX:'hidden',borderRadius:2}}>
                        <Grid container paddingLeft={2.5} paddingRight={2.5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <img style={{width:'50%',borderRadius:5}} src={imagesURL + 'empresas/' + props.reservation.merchant_image}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                                <Typography variant="h5" textAlign={'center'}>{props.reservation.merchant}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                                <Typography variant="subtitle2" >Personas</Typography>
                                <Typography variant="subtitle2" fontWeight={'bold'}>{props.reservation.people}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Typography variant="subtitle2" >Fecha</Typography>
                                <Typography variant="subtitle2" fontWeight={'bold'}>{props.reservation.date}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Typography variant="subtitle2" textAlign={'end'}>Hora</Typography>
                                <Typography variant="subtitle2" fontWeight={'bold'} textAlign={'end'}>{props.reservation.time}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={3}>
                                <DialogContentText id="alert-dialog-slide-description" mt={2}>
                                    {props.reservation.observations}
                                    <br/>
                                </DialogContentText>
                            </Grid>
                            
                        </Grid>
                        {/**Separador */}
                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%',mt:3}}>
                            <Box sx={{width:20,height:20,borderRadius:20,marginLeft:-4,backgroundColor:theme.palette.primary.main}}/>
                            
                            <Box sx={{ borderBottomWidth:2,borderStyle:'dashed',width:'89%'}}/>
                            
                            <Box sx={{width:20,height:20,borderRadius:20,marginRight:-4,backgroundColor:theme.palette.primary.main}}/>
                        </Box>

                        <Grid container>
                            <Box sx={{padding:{xs:1}}}>
                                <Box style={{backgroundColor:'#f5f7fb',borderRadius:10}}>
                                    <Box style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:10}}>
                                        <Typography variant={'subtitle2'}>
                                            {'Muesta este código QR para canjear la reservación'}
                                        </Typography>
                                        
                                        <Box>
                                            <QRCode value={props.reservation.uuid} size={70} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" onClick={props.handleClose}>
                            <Typography>Finalizar</Typography>
                        </Button>
                    </DialogActions>
                </>
            }
            
            
        </Dialog>
    )
}

/*const styles = StyleSheet.create({
    rowSpaceBetween:
    {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        paddingRight:10
    },
    row:
    {
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    column:
    {
        display:'flex',
        flexDirection:'column',
    },
    ticketDivider:
    {
        borderBottomWidth:2,
        borderStyle:'dashed',
        width:'89%',
        //backgroundColor:ticket.estatus === 'Usado' ? '#212121' :'#FFF'
    },
    usedTicketDivider:
    {
        borderBottomWidth:8,
        borderStyle:'solid',
        width:'95%',
        marginLeft:-4,
        marginRight:-4,
        backgroundColor:theme.colors.primary.main
    }
});*/