import { Box, Grid, ListItem, Typography } from "@mui/material";

import horarioBgImg from '../assets/images/horarios.png'
import { useTranslation } from "react-i18next";

export default function Horarios(props)
{
    const {t, i18n} = useTranslation();

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h5" sx={{fontWeight:'bold'}} ml={5}>{t('helpers.workingHours')}</Typography>
            <Grid container spacing={3} sx={{p:5}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} position='relative'>
                    
                    <Box
                        sx={{
                            backgroundImage: "url(" + horarioBgImg + ")",
                            opacity:0.1,
                            backgroundPosition:'center',
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'57%',
                            position:'absolute',
                            right:0,left:0,
                            top:0,bottom:0,

                            //width:300,height:300
                        }}
                    >
                        
                    </Box>
                    {
                        props.horarios.map(item=>
                            <ListItem style={{display:'flex',flexDirection:'row',justifyContent:'space-between',color:'#454545'}}>
                                <Typography variant="body1" component='p'>{item.dia_string}</Typography>
                                <Typography variant="body1" component='p' >{item.inicio === 'null' ?'Cerrado' : item.inicio} - {item.fin === 'null' ?'Cerrado' : item.fin}</Typography>
                            </ListItem>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}