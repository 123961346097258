import { useEffect } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProductsDataTable from "./table";
import { useHistory } from "react-router-dom";
import SectionTitle from "../../../components/sectionTitle";

export default function Products()
{
    useEffect(()=>{
        window.scrollTo(0,0);
    });

    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Productos',link:'/products'}]}/>
            <br/>
            <SectionTitle title='Productos'/>
            <br/>
            <Button
                variant='contained'
                endIcon={<AddCircleIcon/>}
                onClick={()=>redirect('products/add')}
            >
                <Typography>Productos</Typography>
            </Button>
            <br/><br/>

            <ProductsDataTable />
            
        </Container>
    )
}