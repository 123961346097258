import { Container } from "@mui/material";
import { HmacSHA384, SHA384 } from 'crypto-js'
import { useEffect } from "react";


export default function Pagos(){

    useEffect(()=>{
        bancoPlaza()
    },[]);

    const bancoPlaza = async () => {
        const apiKey = '558e47c31cdd4a37baeaafd1e91cdb91';
        const secretKey = 'd223c1996ef74145957ea6f534e5b978';
        const apiPath = '/v1/pagos/p2p/bancos';
        const nonce = (Date.now() * 1000).toString();
        const body = {}

        let signature = `${apiPath}${nonce}${JSON.stringify(body)}`;

        const sig = HmacSHA384(signature,secretKey).toString();
        console.log('nonce ===> ',nonce);
        console.log('SIGNTAURE ===> ',sig);

        const options = {
            url:`https://apiqa.bancoplaza.com:8585/${apiPath}`,
            headers:{
                'api-key': apiKey,
                'api-signature': sig,
                'nonce': nonce
            },
            body: body,
            json: true
        }

        //const response = await fetch(options);
        try
        {
            

            let response = await fetch(
                `https://apiqa.bancoplaza.com:8585/v1/pagos/p2p/bancos`,
                {
                    method : 'GET',
                    headers : 
                    {
                        //'Accept' : 'application/json',
                        'api-key': apiKey,
                        'api-signature': sig,
                        'nonce': nonce
                    },
                    mode:'cors'
                    //body:body
                }
            );

            let responseJson = await response.json();
            console.log(responseJson);

        }
        catch(error)
        {
            return console.log(error);;
        }
    }

    return(
        <Container sx={{mt:10}}>
            PAGOS
        </Container>
    )
}