import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Box, Button, Chip, Container, Dialog, Divider, Grid, IconButton, MenuItem, Paper, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";

import PropTypes from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import UserContext from "../navigation/context/userContext";
import { GetPaymentMethods } from "../controllers/MerchantsController";

import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ElementProps, Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { CreatePaymentIntent } from "../controllers/StripeController";
import { deposit, depositWithoutLogin, payWithoutLogin, payWithoutPin } from "../controllers/WalletController";
import moment from "moment";
import QUDProgress from "./progress";
import ResponseComponent from "./responseComponent";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { stripePromisePK } from "../apiRoutes/apiBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import MercadoPagoColombia from "./payments/MPColombia";
import { process_payment } from "../controllers/MercadoPagoController";

const stripePromise = loadStripe(stripePromisePK);

/**Tabs de formas de pago */
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ pt: 3 }}>
                {children}
            </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    paymentMethods: [],
    paymentMethod:null, //para almacenar los datos de la forma de pago seleccionada
    bankAccounts: [],
    bankAccountsAux:[],
    bankAccountSelected:null,
    banks_ve:[],
    exchange_rate:0,
    //pago movil
    bank:'',            errorBank: false,
    prefix:'0424',
    phoneNumber:'',     errorPhoneNumber: false,
    lastDigits:'',      errorLastDigits: false,
    startDate: new Date(),       errorStartDate: false,
    reference:'',

    //zelle
    ownerName: '',              errorOwnerName:false,
    ownerEmail: '',             errorOwnerEmail:false,
    confirmationNunmber: '',    errorConfirmationNumber:false
}
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  

/**
 * @prop {type} 
 * @prop {merchantUUID}
 * @prop {amount}
 * @prop {handleSubmit}
 * @prop {action}
 */
export default function Payments(props)
{
    const theme = useTheme();
    const {userData} = useContext(UserContext);
    const [showModal,setShowModal] = useState(true);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        paymentMethods, paymentMethod, bankAccounts, bankAccountsAux, bankAccountSelected,
        payments, bank, errorBank, prefix, phoneNumber, errorPhoneNumber,
        lastDigits, errorLastDigits, startDate, errorStartDate,
        reference, banks_ve, exchange_rate,
        ownerName, errorOwnerName ,ownerEmail, errorOwnerEmail,confirmationNunmber,errorConfirmationNumber
    } = state;

    const [valueTab,setValueTab] = useState(0);
    const [cash,setCash] = useState([
        {denomination:100,quantity:0},
        {denomination:50,quantity:0},
        {denomination:20,quantity:0},
        {denomination:10,quantity:0},
        {denomination:5,quantity:0},
        {denomination:1,quantity:0},
    ]);
    const [sumCash,setSumCash] = useState(0);

    const [isLoading,setIsLoading] = useState(false);
    const [complete,setComplete] = useState(false);
    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [actionText,setActionText] = useState('Finalizar');
    const [secondaryText,setSecondaryText] = useState('SI');

    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
     

    useEffect(()=>{
        handleGetPaymentsMethods();
    },[]);

    const redirect = (route) => {
        history.push(route)
    }

    const handleStartDateChange = (newValue) => {
        setState({field:'startDate',value:newValue});
    };

    
    const handleBankAccountSelected = async (item) => {
        setState({field:'bankAccountSelected',value:item})
    }

    /**
     * Gestion de pagos
     */
    /**
     * Para obtener las formas de pago de la empresa
     * @param uuid de la empresa
     * @returns formas de pago y accounts bancarias
     */
    const handleGetPaymentsMethods = async () => {
        let response = await GetPaymentMethods(props.merchantUUID);
        if(response.success === true)
        {
            setState({field:'banks_ve',value:response.data.banks_ve});
            setState({field:'paymentMethods',value:response.data.payment_methods});
            setState({field:'bankAccountSelected',value:response.data.payment_methods[0].accounts[0]});
            if(props.event_id === 24)
                setState({field:'exchange_rate',value:44.73});
            else
                setState({field:'exchange_rate',value:parseFloat(response.data.exchange_rate)})
        }
    }

    const handlePaymentMethod = (item) => {
        console.log('Payment method ===> ',item);
        setState({field:'paymentMethod',value:item});
    }

    const handlePaymentMethodSelected = async () => {

        let _aux = await Promise.all(bankAccounts.filter(item=>{
            return item.payment_method_id === paymentMethod.id
        }));
        
        setState({field:'bankAccountsAux',value:_aux});
        setState({field:'bankAccountSelected',value:paymentMethod?.accounts[0]});
    }

    useEffect(()=>{
        handlePaymentMethodSelected();
    },[paymentMethod]);


    const handleChangeTabs = (event, newValue) => {
        //setState({field:'paymentMethod',value:newValue})
        //console.log('event ===> ',paymentMethods[newValue].accounts);
        setState({field:'paymentMethod',value:paymentMethods[newValue]});
        setState({field:'bankAccounts',value:paymentMethods[newValue].accounts})
        setValueTab(newValue);
    };
    

    /**Cuando la gente paga con cash debe indicar los montos de los billetes
     * con los cuales va a realizar el pago
     */
    const handleCashPayment = (denomination,operator) => {
        const newState = cash.map((item)=>{
            if(item.denomination === denomination)
            {
                let _quantity = item.quantity;
                if(operator === '+')
                    _quantity = _quantity + 1;
                else
                {
                    if(_quantity > 0)
                        _quantity = _quantity - 1;
                }

                return {...item,quantity:_quantity}
            }

            return item;
        });

        setCash(newState);
        //setState({field:'cash',state:newState});
    }

    const handleSumCash = async () => {
        let sum = 0;
        await Promise.all(
            cash.map(item=>
                sum += parseInt(item.denomination * item.quantity)
            )
        )
        console.log('La suma es ===> ',sum)
        return setSumCash(sum);
    }

    useEffect(()=>{
        handleSumCash();
    },[cash]);


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handlePagoMovilFormVerification = () => {
        var ban = 0;

        if(bank === '')
        {
            setState({field:'errorBank',value:true});
            ban = 1;
        }

        if(phoneNumber === '')
        {
            setState({field:'errorPhoneNumber',value:true});
            ban = 1;
        }

        if(lastDigits === '')
        {
            setState({field:'errorLastDigits',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const handleZelleFormVerification = () => {
        var ban = 0;

        if(ownerName === '')
        {
            setState({field:'errorOwnerName',value:true});
            ban = 1;
        }

        if(ownerEmail === '')
        {
            setState({field:'errorOwnerEmail',value:true});
            ban = 1;
        }

        if(confirmationNunmber === '')
        {
            setState({field:'errorConfirmationNunmber',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const handleBinanceFormVerification = () => {
        var ban = 0;

        if(ownerName === '')
        {
            setState({field:'errorOwnerName',value:true});
            ban = 1;
        }

        if(ownerEmail === '')
        {
            setState({field:'errorOwnerEmail',value:true});
            ban = 1;
        }

        if(confirmationNunmber === '')
        {
            setState({field:'errorConfirmationNunmber',value:true});
            ban = 1;
        }
        
        return ban;
    }


    const handleOtrosFormVerification = () => {
        var ban = 0;

        if(confirmationNunmber === '')
        {
            setState({field:'errorConfirmationNunmber',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const handleStripeForm = (e) => {
        if(e.complete === true)
            return setComplete(true);

        return setComplete(false);
    }


    const handleCreatePaymentIntent = async () => {
        let paymentIntentAmount = parseFloat(props.amount + (props.amount*0.1) + 0.3).toFixed(2);
        paymentIntentAmount = parseFloat(props.amount);
        let response = await CreatePaymentIntent(paymentIntentAmount,props.merchantUUID,props.customer);
        if(response.success === true)
        {
            return response.data;
        }
        else
        {
            setTitle('Operación Fallida');
            setMessage(response.message);
            setSuccess(false);
            setShow(true);
        }
    }


    const handleSubmit = async () => {
        let fecha = moment().format('YYYY-MM-DD');
        
        if(bankAccountSelected.payment_method.toLowerCase() === 'stripe')
        {
            if(complete === false)
                return;
            
            setIsLoading(true)
            await elements.submit();
            //se crea el payment intent
            const paymentIntent = await handleCreatePaymentIntent();
            const stripeResult = await stripe.confirmPayment({
                elements,
                clientSecret:paymentIntent.client_secret,
                confirmParams:{
                    payment_method_data:{
                        billing_details:{
                            email: props.customer.correo,
                            name: props.customer.nombre+' '+props.customer.apellido
                        }
                    },
                },
                redirect:'if_required'
            });

            if(stripeResult.error)
            {
                console.log('STRIPE ERROR ===> ',stripeResult.error.message);
                //se maneja el error
                setTitle('Operación Fallida');
                setMessage(stripeResult.error.message);
                setSuccess(false);
                setActionText('Intente nuevamente')
                setShow(true);
            }
            else
            {
                //realizamos el deposito en QUD para la cuenta del usuario
                let depositAmount = parseFloat(props.amount + (props.amount*0.1) + 0.3).toFixed(2);
                depositAmount = parseFloat(depositAmount);
                //fecha del deposito en formato YYYY-MM-DD
                let responseDeposito;
                if(userData.user)
                {
                    responseDeposito = await deposit(
                        2,fecha,depositAmount,
                        bankAccountSelected.id,bankAccountSelected.bank_id,
                        '1234','Recarga de creditos QUD para consumo',
                        stripeResult.paymentIntent.id
                    );
                }
                else
                    responseDeposito = await depositWithoutLogin(
                        props.customer.correo,
                        2,fecha,depositAmount,
                        bankAccountSelected.id,bankAccountSelected.bank_id,
                        '1234','Recarga de creditos QUD para consumo',
                        stripeResult.paymentIntent.id
                    );
                
                if(responseDeposito.success === true)
                {
                    //luego de realizar el deposito, si la operacion es exitosa llamanamos al callback
                    
                    //Validar si se pide ejecutar un pago o no (esto puede ser un deposito)
                    if(props.type === 'deposit')
                        props.handleSubmit(true,[/**Agregar respuesta del deposito*/]);
                    else
                    {
                        let responsePaymentWithoutPin;
                        if(userData.user)
                            //Ahora se hace el pago del usuario al comercio
                            responsePaymentWithoutPin = await payWithoutPin(props.amount,props.merchantUUID,props.observations);
                        else
                            responsePaymentWithoutPin = await payWithoutLogin(props.amount,props.merchantUUID,props.observations,props.customer.correo,props.event_id);
                        
                        if(responsePaymentWithoutPin.success === true)
                        {
                            let pago =
                            {
                                id_numero_cuenta: responsePaymentWithoutPin.data.to_account,
                                monto: responsePaymentWithoutPin.data.amount,
                                referencia: responsePaymentWithoutPin.data.referencia,
                            };
                            
                            props.handleSubmit(true,pago,true);
                        }
                    }


                    //del componente padre
                    console.log('AQUI DEBO LLAMAR AL CALLBACK')
                    props.handleSubmit(true);
                }
                else
                {
                    //en caso de error notificarle al usuario hacer un capture 
                    //con los datos del pago para que se contacte con el soporte de QUD
                    console.log('NOTIFICAR EL ERROR')
                }
                
                
                setIsLoading(false)
                
            }
        }
        else if(bankAccountSelected.payment_method.toLowerCase() === 'pago movil')
        {
            if(handlePagoMovilFormVerification() === 1)
                return;

            let paymentCash=
                {
                    referencia:`${parseFloat(props.amount*exchange_rate).toFixed(2)} VES - ${prefix+phoneNumber} - #${lastDigits}`,
                    id_numero_cuenta: bankAccountSelected.id,
                    monto: props.amount
                };

            props.handleSubmit(true,paymentCash,false);
        }
        else if(bankAccountSelected.payment_method.toLowerCase() === 'zelle')
        {
            if(handleZelleFormVerification() === 1)
                return;

            let paymentCash=
            {
                referencia:`${ownerName} - ${ownerEmail} - #${confirmationNunmber}`,
                id_numero_cuenta: bankAccountSelected.id,
                monto: props.amount
            };

            props.handleSubmit(true,paymentCash,false);
        }
        else if(bankAccountSelected.payment_method.toLowerCase() === 'binance')
        {
            if(handleBinanceFormVerification() === 1)
                return;

            let paymentCash=
            {
                referencia:`${ownerName} - ${ownerEmail} - #${confirmationNunmber}`,
                id_numero_cuenta: bankAccountSelected.id,
                monto: props.amount
            };

            props.handleSubmit(true,paymentCash,false);
        }
        else if(bankAccountSelected.payment_method.toLowerCase() === 'otros')
            {
                if(handleOtrosFormVerification() === 1)
                    return;
    
                let paymentCash=
                {
                    referencia:`#${confirmationNunmber}`,
                    id_numero_cuenta: bankAccountSelected.id,
                    monto: props.amount
                };
    
                props.handleSubmit(true,paymentCash,false);
            }
        else if(bankAccountSelected.bank === 'QUD')
        {
            //Se debe verificar el saldo de QUD del cliente
            props.handleSubmit(true);
        }
        else
        {

        }
        setIsLoading(false)
    }

    const handleSubmitMercadoPago = async (responseMP) => {
        let fecha = moment().format('YYYY-MM-DD');
        if(responseMP.success === true)
        {
            let depositAmount = parseFloat(props.amount + (props.amount*0.1) + 0.3).toFixed(2);
            depositAmount = parseFloat(depositAmount);
            //fecha del deposito en formato YYYY-MM-DD
            let responseDeposito;
            if(userData.user)
            {
                responseDeposito = await deposit(
                    2,fecha,depositAmount,
                    bankAccountSelected.id,bankAccountSelected.bank_id,
                    '1234','Recarga de creditos QUD para consumo',
                    responseMP.data.payment_info.id
                );
            }
            else
                responseDeposito = await depositWithoutLogin(
                    props.customer.correo,
                    2,fecha,depositAmount,
                    bankAccountSelected.id,bankAccountSelected.bank_id,
                    '1234','Recarga de creditos QUD para consumo',
                    responseMP.data.payment_info.id
                );
            
            if(responseDeposito.success === true)
            {
                //luego de realizar el deposito, si la operacion es exitosa llamanamos al callback
                
                //Validar si se pide ejecutar un pago o no (esto puede ser un deposito)
                if(props.type === 'deposit')
                    props.handleSubmit(true,[/**Agregar respuesta del deposito*/]);
                else
                {
                    let responsePaymentWithoutPin;
                    if(userData.user)
                        //Ahora se hace el pago del usuario al comercio
                        responsePaymentWithoutPin = await payWithoutPin(props.amount,props.merchantUUID,props.observations);
                    else
                        responsePaymentWithoutPin = await payWithoutLogin(props.amount,props.merchantUUID,props.observations,props.customer.correo,props.event_id);
                    
                    if(responsePaymentWithoutPin.success === true)
                    {
                        let pago =
                        {
                            id_numero_cuenta: responsePaymentWithoutPin.data.to_account,
                            monto: responsePaymentWithoutPin.data.amount,
                            referencia: responsePaymentWithoutPin.data.referencia,
                        };
                        
                        props.handleSubmit(true,pago,true);
                    }
                }


                //del componente padre
                console.log('AQUI DEBO LLAMAR AL CALLBACK')
                props.handleSubmit(true);
            }
            else
            {
                //en caso de error notificarle al usuario hacer un capture 
                //con los datos del pago para que se contacte con el soporte de QUD
                console.log('NOTIFICAR EL ERROR')
            }
        }
        else
        {
            props.handleSubmit(false,[],true,responseMP.message);
        }
    }



    return(
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {
                props.country !== 'co'
                ?
                <>
                    <Typography variant='h6' color={'link.main'} textAlign={'center'} fontWeight={'bold'} mb={3}>
                        Seleccione un método de pago
                    </Typography>

                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueTab} onChange={handleChangeTabs} aria-label="basic tabs example">
                                    {
                                        paymentMethods &&
                                        paymentMethods.map((item,index)=>
                                            <Tab
                                                label={
                                                    <Typography fontWeight={'bold'}>
                                                        {item.name === 'Stripe' ? 'Internacional' : item.name}
                                                    </Typography>
                                                }
                                                {...a11yProps(index)} 
                                            />
                                        )
                                    }
                                </Tabs>
                            </Box>
                            {
                                paymentMethods &&
                                paymentMethods.map((item,index)=>
                                    <CustomTabPanel value={valueTab} index={index}>
                                        {
                                            item.name === 'Stripe' &&
                                            
                                                    <Paper elevation={4}>
                                                        {/*<Typography fontSize={12} mb={2}>
                                                            <FontAwesomeIcon icon={faCircleExclamation} color="#ff9d00"/> 
                                                            &nbsp;Los pagos con tarjeta de débito/crédito  estan sujetos a una comisión del 10% de la tarifa + 0.30$
                                                        </Typography>*/}
                                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between', mb:2}}>
                                                            <Typography variant="h6" fontWeight={'bold'}>
                                                                Total a pagar
                                                            </Typography>
                                                            <Typography variant="h6" fontWeight={'bold'}>
                                                                {parseFloat(props.amount).toFixed(2)}
                                                            </Typography>
                                                            {/*<Typography variant="h6" fontWeight={'bold'}>
                                                                {parseFloat((props.amount)+(props.amount*0.1)+0.3).toFixed(2)}
                                                            </Typography>*/}
                                                        </Box>
                                                        
                                                        
                                                            <PaymentElement
                                                                onChange={(e)=>handleStripeForm(e)}
                                                            />
                                                        
                                                    </Paper>
                                        }

                                        {
                                            item.name === 'Pago Movil' &&
                                            <Paper elevation={4}>
                                                {/*<Typography fontSize={15} mb={2}>
                                                    <FontAwesomeIcon icon={faCircleExclamation} color="#ff9d00"/> 
                                                    &nbsp;Tasa de cambio BCV = {exchange_rate}
                                                </Typography>*/}
                                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between', mb:2}}>
                                                    <Typography variant="h6" fontWeight={'bold'}>
                                                        Total a pagar
                                                    </Typography>
                                                    <Typography variant="h6" fontWeight={'bold'}>
                                                        {parseFloat(props.amount*exchange_rate).toFixed(2)} <span style={{fontSize:12}}>VES</span>
                                                    </Typography>
                                                </Box>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'}>
                                                            Realiza el {item.name} a:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Teléfono
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].account_number} 
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Documento
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].document}
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Monto
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {parseFloat(props.amount*exchange_rate).toFixed(2)} Bs.
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography variant="body1">Banco</Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].bank} ({item.accounts[0].routing_number})
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    {/**Se registran los datos del pago */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'}>
                                                            Registra los datos del<br/>Pago Móvil realizado
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            size="small"
                                                            variant="outlined"
                                                            label='Banco'
                                                            id="bank"
                                                            name="bank"
                                                            value={bank}
                                                            onChange = { onChange }
                                                            onBlur={ () => (bank === '' ? setState({field:'errorBank',value:true}) : setState({field:'errorBank',value:false}) ) }
                                                            error = {errorBank}
                                                            helperText={errorBank && 'Selecciona el banco del que realizaste el pago'}
                                                        >
                                                            <MenuItem value=''>Seleccione el banco</MenuItem>
                                                            {
                                                                banks_ve.lenght !== 0 &&
                                                                banks_ve.map(item=>
                                                                    <MenuItem key={item.uuid} value={item.name}>{item.name}</MenuItem>
                                                                )
                                                                
                                                            }
                                                        </TextField>
                                                    </Grid>

                                                    {/**Telefono */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label='prefijo'
                                                                    id="prefix"
                                                                    name="prefix"
                                                                    value={prefix}
                                                                    onChange = { onChange }
                                                                >
                                                                    <MenuItem key='0424' value='0424'>0424</MenuItem>
                                                                    <MenuItem key='0414' value='0414'>0414</MenuItem>
                                                                    <MenuItem key='0426' value='0426'>0426</MenuItem>
                                                                    <MenuItem key='0416' value='0416'>0416</MenuItem>
                                                                    <MenuItem key='0412' value='0412'>0412</MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label='Teléfono '
                                                                    id="phoneNumber"
                                                                    name="phoneNumber"
                                                                    value={phoneNumber}
                                                                    onChange = { onChange }
                                                                    onBlur={ () => (phoneNumber === '' ? setState({field:'errorPhoneNumber',value:true}) : setState({field:'errorPhoneNumber',value:false}) ) }
                                                                    error = {errorPhoneNumber}
                                                                    helperText={errorPhoneNumber && 'Indica el teléfono del que realizaste el pago'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {/**Fecha - Referencia */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                                <LocalizationProvider dateAdapter={ AdapterMoment }>
                                                                    <MobileDatePicker
                                                                        label='Fecha del pago'
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={new Date()}
                                                                        value={startDate}
                                                                        onChange={handleStartDateChange}
                                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label='Últimos 4 dígitos'
                                                                    id="lastDigits"
                                                                    name="lastDigits"
                                                                    value={lastDigits}
                                                                    onChange = { onChange }
                                                                    onBlur={ () => (lastDigits === '' ? setState({field:'errorLastDigits',value:true}) : setState({field:'errorLastDigits',value:false}) ) }
                                                                    error = {errorLastDigits}
                                                                    helperText={errorLastDigits && 'Indica los últimos 4 dígitos de la referencia del pago'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        }

                                        {
                                            item.name === 'Zelle' &&
                                            <Paper elevation={4}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'}>
                                                            Realiza el {item.name} a:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Nombre
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].description}
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Correo/Teléfono
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].account_number} 
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Descripción
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                Pago QUD
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Monto
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {parseFloat(props.amount).toFixed(2)} USD
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    {/**Se registran los datos del pago */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'}>
                                                            Registra los datos del<br/>Zelle realizado
                                                        </Typography>
                                                    </Grid>
                                                    

                                                    {/**Onwer Name */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            label='Nombre de la persona que envía'
                                                            id="ownerName"
                                                            name="ownerName"
                                                            value={ownerName}
                                                            onChange = { onChange }
                                                            onBlur={ () => (ownerName === '' ? setState({field:'errorOwnerName',value:true}) : setState({field:'errorOwnerName',value:false}) ) }
                                                            error = {errorOwnerName}
                                                            helperText={errorOwnerName && 'Indica el nombre de la persona que envía el pago'}
                                                        />
                                                    </Grid>

                                                    {/**Owner Email */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            label='Correo de la persona que envía'
                                                            id="ownerEmail"
                                                            name="ownerEmail"
                                                            value={ownerEmail}
                                                            onChange = { onChange }
                                                            onBlur={ () => (ownerEmail === '' ? setState({field:'errorOwnerEmail',value:true}) : setState({field:'errorOwnerEmail',value:false}) ) }
                                                            error = {errorOwnerEmail}
                                                            helperText={errorOwnerEmail && 'Indica el correo de la persona que envía el pago'}
                                                        />
                                                    </Grid>

                                                    {/**Fecha - Referencia */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                                <LocalizationProvider dateAdapter={ AdapterMoment }>
                                                                    <MobileDatePicker
                                                                        label='Fecha del pago'
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={new Date()}
                                                                        value={startDate}
                                                                        onChange={handleStartDateChange}
                                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label='Número de comprobante'
                                                                    id="confirmationNunmber"
                                                                    name="confirmationNunmber"
                                                                    value={confirmationNunmber}
                                                                    onChange = { onChange }
                                                                    onBlur={ () => (confirmationNunmber === '' ? setState({field:'errorConfirmationNumber',value:true}) : setState({field:'errorConfirmationNumber',value:false}) ) }
                                                                    error = {errorConfirmationNumber}
                                                                    helperText={errorConfirmationNumber && 'Indica el número de comprobante del pago'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        }

                                        {
                                            item.name === 'Binance' &&
                                            <Paper elevation={4}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'}>
                                                            Realiza el pago a:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Nombre
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].description}
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Correo
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {item.accounts[0].account_number} 
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Descripción
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                Pago QUD
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={-3}>
                                                        <Typography variant="body1">
                                                            Monto
                                                        </Typography>
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {parseFloat(props.amount).toFixed(2)} USD
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </IconButton>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    {/**Se registran los datos del pago */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'}>
                                                            Registra los datos del<br/>pago realizado
                                                        </Typography>
                                                    </Grid>
                                                    

                                                    {/**Onwer Name */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            label='Nombre de la persona que envía'
                                                            id="ownerName"
                                                            name="ownerName"
                                                            value={ownerName}
                                                            onChange = { onChange }
                                                            onBlur={ () => (ownerName === '' ? setState({field:'errorOwnerName',value:true}) : setState({field:'errorOwnerName',value:false}) ) }
                                                            error = {errorOwnerName}
                                                            helperText={errorOwnerName && 'Indica el nombre de la persona que envía el pago'}
                                                        />
                                                    </Grid>

                                                    {/**Owner Email */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            label='Correo de la persona que envía'
                                                            id="ownerEmail"
                                                            name="ownerEmail"
                                                            value={ownerEmail}
                                                            onChange = { onChange }
                                                            onBlur={ () => (ownerEmail === '' ? setState({field:'errorOwnerEmail',value:true}) : setState({field:'errorOwnerEmail',value:false}) ) }
                                                            error = {errorOwnerEmail}
                                                            helperText={errorOwnerEmail && 'Indica el correo de la persona que envía el pago'}
                                                        />
                                                    </Grid>

                                                    {/**Fecha - Referencia */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                                <LocalizationProvider dateAdapter={ AdapterMoment }>
                                                                    <MobileDatePicker
                                                                        label='Fecha del pago'
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={new Date()}
                                                                        value={startDate}
                                                                        onChange={handleStartDateChange}
                                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label='Número de comprobante / ID de la orden'
                                                                    id="confirmationNunmber"
                                                                    name="confirmationNunmber"
                                                                    value={confirmationNunmber}
                                                                    onChange = { onChange }
                                                                    onBlur={ () => (confirmationNunmber === '' ? setState({field:'errorConfirmationNumber',value:true}) : setState({field:'errorConfirmationNumber',value:false}) ) }
                                                                    error = {errorConfirmationNumber}
                                                                    helperText={errorConfirmationNumber && 'Indica el número de comprobante del pago'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        }

                                        {
                                            item.name === 'Otros' &&
                                            <Paper elevation={4}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography variant="h6">
                                                            {item.accounts[0].account_number}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography variant="h6">
                                                            {item.accounts[0].description}
                                                        </Typography>
                                                    </Grid>

                                                    {/**Fecha - Referencia */}
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                                <LocalizationProvider dateAdapter={ AdapterMoment }>
                                                                    <MobileDatePicker
                                                                        label='Fecha del pago'
                                                                        inputFormat="DD/MM/YYYY"
                                                                        minDate={new Date()}
                                                                        value={startDate}
                                                                        onChange={handleStartDateChange}
                                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    label='Número de comprobante / Recibo'
                                                                    id="confirmationNunmber"
                                                                    name="confirmationNunmber"
                                                                    value={confirmationNunmber}
                                                                    onChange = { onChange }
                                                                    onBlur={ () => (confirmationNunmber === '' ? setState({field:'errorConfirmationNumber',value:true}) : setState({field:'errorConfirmationNumber',value:false}) ) }
                                                                    error = {errorConfirmationNumber}
                                                                    helperText={errorConfirmationNumber && 'Indica el número de comprobante del pago'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        }

                                        {
                                            item.name === 'Efectivo' &&
                                            <Paper>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                        <Typography textAlign={'center'} variant="h6">
                                                            Para realizar pagos en efectivo debe contactar al Organizador del evento.
                                                        </Typography>
                                                        <Button variant="text" color="link" onClick={()=>redirect(`/eventos/${props.event_id}`)}>
                                                            <Typography fontWeight={'bold'}>Ver Datos del evento</Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        }

                                        {
                                            item.name === 'Punto de Venta' &&
                                            <Paper>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                        <Typography textAlign={'center'} variant="h6">
                                                            Para realizar pagos con Punto de Venta debe contactar al Organizador del evento.
                                                        </Typography>
                                                        <Button variant="text" color="link" onClick={()=>redirect(`/eventos/${props.event_id}`)}>
                                                            <Typography fontWeight={'bold'}>Ver Datos del evento</Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        }

                                        {/*
                                            item.name === 'Efectivo' &&
                                            <Paper>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Typography textAlign={'center'} variant="h6">
                                                            Indica la denominación de los billetes en $ con los que va a cancelar
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Box sx={{display:'flex',flexDirection:'column', justifyContent:'space-between'}}>
                                                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                                                <Typography variant="h6">Denominación</Typography>
                                                                <Typography variant="h6">Cantidad</Typography>
                                                            </Box>
                                                            <Divider/>
                                                            {
                                                                cash&&
                                                                cash.map((item)=>
                                                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                                                        <Typography variant="h6">{item.denomination}</Typography>
                                                                        <Box sx={{display:'flex',flexDirection:'row', alignItems:'center'}}>
                                                                            <IconButton color="primary"
                                                                                onClick={()=>handleCashPayment(item.denomination,'-')}
                                                                            >
                                                                                <FontAwesomeIcon icon={faMinusCircle}/>
                                                                            </IconButton>
                                                                            <Typography variant="h6">{item.quantity}</Typography>
                                                                            <IconButton color="primary"
                                                                                onClick={()=>handleCashPayment(item.denomination,'+')}
                                                                            >
                                                                                <FontAwesomeIcon size="1x" icon={faPlusCircle}/>
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                )  
                                                            }
                                                            <Divider/>
                                                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'flex-end',alignItems:'flex-end'}}>
                                                                <Typography variant="h6">Total efectivo: {parseFloat(sumCash).toFixed(2)} $</Typography>
                                                                <Typography variant="h6">Total vuelto: {sumCash-props.amount < 0 ? '0.00' : parseFloat(sumCash-props.amount).toFixed(2)} $</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        */}
                                    </CustomTabPanel>
                                )
                            }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'} mb={2}>
                            <Button variant="contained" onClick={()=>handleSubmit()}>
                                <Typography fontWeight={'bold'}>{props.action}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <MercadoPagoColombia
                        title={props.title}
                        amount={parseFloat(props.amount)}
                        handleOnSubmit={handleSubmitMercadoPago}
                    />
                </>
            }

            
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                title={title}
                show={show}
                success={success}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>setShow(false)}
            />
        </Grid>
    )
}