import { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, Avatar, Box, Button, Card, Container, Grid, IconButton, List, ListItem, ListItemAvatar, Divider, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";

import UserContext from "../../navigation/context/userContext";
import QRCode from "react-qr-code";


import publicidadTLS from '../../assets/images/publicidadTLS.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faCirclePlus, faCopy, faEye, faGifts, faInstitution, faLink, faQrcode, faStore, faTags, faTicket, faTools, faUser, faUserCog } from "@fortawesome/free-solid-svg-icons";

import imagen1 from '../../assets/images/dashboard/imagen1.jpeg';

import triangeImg from '../../assets/images/triangle-light.png';
import trophyImg from '../../assets/images/trophy.png';
import GastromediaEvent from "./events/gastromedia";

import Lottie from "lottie-react";
import buildingAnimation from '../../assets/lottie/building.json';
import { domain, imagesURL } from "../../apiRoutes/apiBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ShareButton from "../../components/ShareButton";
import ResponseComponent from "../../components/responseComponent";

import publicidad_img from '../../assets/images/publicidadEvento2.png' //'../../../assets/images/publicidadEvento2.png';
import { getViews } from "../../controllers/company/AnalyticsController";
import QUDAreaChart from "../../components/charts/AreaChart";





export default function CompanyHome()
{
    const {userData,setUserData} = useContext(UserContext);

    const [date,setDate] = useState(Date.now())
    const [copySuccess, setCopySuccess] = useState('');

    const [dailyStatistics,setDailyStatistics] = useState([]);
    const [totalViews,setTotalViews] = useState(0);
    const [topProducts,setTopProducts] = useState([]);

    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');

    const history = useHistory();
    const theme = useTheme();

    useEffect(()=>{
        console.log(userData.user)
        setInterval(()=>setDate(new Date()),1000);
        handleGetViewsAnalytics();
    },[]);


    const handleGetViewsAnalytics = async () => {
        const response = await getViews('merchant',userData.user.id);
        if(response.success === true)
        {
            setDailyStatistics(response.data.dailyStatistics);
            setTotalViews(response.data.totalViews);
            setTopProducts(response.data.topProducts);
        }
    }

    

    const redirect = (route) => {
        history.push(route);
    }

    // your function to copy here

    const handleCopy = (text) => {
        const url = domain+'merchants/'+userData.user.uuid+'/'+text;
        navigator.clipboard.writeText(url);
        
        setSuccess(true);
        setMessage('Enlace copiado exitosamente')
        setShow(true);
        //setTimeout(()=>{setCopySuccess(false)},5000)
        //e.clipboardData.setData("Text", 'HOLA MUNDO!');
    }

    //const date = Date.now();
    const hoy = new Date(date);

    

 

    return(
        <Container maxWidth='xl'>
            <ResponseComponent
                show={show}
                success={success}
                message={message}
                actionText='Finalizar'
                onClick={()=>setShow(false)}
            />
            <Grid container spacing={3} sx={{mt:5,mb:5}} alignItems={'center'} justifyContent={'center'}>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                    <Paper elevation={4} sx={{padding:0,height:230,mt:3}}>
                        <Box
                            sx={{
                                backgroundImage: `url(${publicidad_img})`,
                                backgroundRepeat: 'no-repeat', // Controla la repetición de la imagen
                                backgroundSize: 'cover', // Ajusta la imagen para cubrir todo el contenedor
                                backgroundPosition:'center',
                                height: '100%', // Ajusta la altura del contenedor
                                width: '100%', // Ajusta el ancho del contenedor
                                borderRadius:2,
                            }}
                        >
                        <Grid container spacing={3} justifyContent={'center'} alignItems={'center'} p={2}>
                            <Grid item lg={12} xl={12}>
                                <Typography variant="h4" color={'primary.contrastText'} textAlign={'center'}>
                                    ¡Crea y organiza tus eventos <br/> de manera sencilla!
                                </Typography>
                                <Typography variant="h6" fontWeight={'bold'} color={'link.main'} textAlign={'center'} mt={5}>
                                    Planifica, promociona y gestiona tus eventos desde cualquier lugar.
                                </Typography>
                            </Grid>
                        </Grid>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item md={4} lg={4} xl={4} >
                    <Paper elevation={4} >
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3} xl={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faTicket}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9} xl={9}>
                                <Typography variant="h4" fontWeight={'bold'}>Eventos</Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Typography variant='body2'>
                                    Crea, organiza y promociona tus eventos online o presenciales.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{textTransform:'none'}}
                                    onClick={()=>redirect('/events')}
                                >
                                    <Typography color={'primary.main'}>Gestionar Eventos</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                
                
                <Grid item md={6} lg={4} xl={4}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3} xl={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faGifts}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9} xl={9}>
                                <Typography variant="h5" fontWeight={'bold'}>Cupones</Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Typography variant='body2'>
                                    Ofrece cupones y descuentos a tus clientes para fidelizarlos y aumentar tus ventas.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{textTransform:'none'}}
                                    onClick={()=>redirect('/coupons/add')}
                                >
                                    <Typography color={'primary.main'}>Emitir Cupon</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item md={6} lg={4} xl={4}>
                    <Paper elevation={4} >
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.main} size="2x" icon={faTags}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9}>
                                <Typography variant="h5" fontWeight={'bold'}>Catálogo digital</Typography>
                            </Grid>
                            <Grid item lg={12}>
                                <Typography variant='body2'>
                                    Crea un catálogo online de tus productos/servicios para que este al alcance de tus clientes 24/7.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth size="small" variant="outlined" color="secondary" sx={{textTransform:'none'}} onClick={()=>redirect('/categories')}>
                                    <Typography color={'primary.main'}>Categorías</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth size="small" variant="outlined" color="secondary" sx={{textTransform:'none'}} onClick={()=>redirect('/products')}>
                                    <Typography color={'primary.main'}>Productos</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                

                <Grid item md={6} lg={4} xl={4}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3} xl={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faLink}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9} xl={9}>
                                <Typography variant="h5" fontWeight={'bold'}>Enlaces</Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Typography variant='body2'>
                                    Comparte los siguientes enlaces con tus clientes para que vean lo que ofreces.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth size="small" variant="outlined" color="secondary" sx={{textTransform:'none'}} onClick={()=> handleCopy('profile')}>
                                    <Typography color={'primary.main'}>Landing page</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth size="small" variant="outlined" color="secondary" sx={{textTransform:'none'}} onClick={()=>handleCopy('menu')}>
                                    <Typography color={'primary.main'}>Catálogo Digital</Typography>
                                </Button>
                            </Grid>
                            {/*<Grid item lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                    <Typography variant="body1">
                                        Landing page
                                    </Typography>
                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                        <IconButton edge='end' size="medium" onClick={()=> handleCopy('profile')} sx={{mr:1}}>
                                            <FontAwesomeIcon icon={faCopy}/>
                                        </IconButton>
                                        <ShareButton url={`${domain}merchants/${userData.user.uuid}/profile`}/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                    <Typography variant="body1">
                                        Catálogo Digital
                                    </Typography>
                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                        <IconButton edge='end' size="medium" onClick={()=> handleCopy('menu')} sx={{mr:1}}>
                                            <FontAwesomeIcon icon={faCopy}/>
                                        </IconButton>
                                        <ShareButton url={`${domain}merchants/${userData.user.uuid}/menu`}/>
                                    </Box>
                                </Box>
                            </Grid>*/}
                        </Grid>
                    </Paper>
                </Grid>




                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h6" fontWeight={'bold'}>
                                Analitica de Visualizaciones
                            </Typography>
                        </Grid>
                        <Grid item lg={8} xl={8}>
                            <Grid container spacing={3}>
                                <Grid item lg={6}>
                                    <Paper elevation={4}>
                                        <Grid container spacing={3} alignItems={'center'}>
                                            <Grid item lg={3} xl={3}>
                                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faStore}/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item lg={9} xl={9}>
                                                <Typography variant="h6" fontWeight={'bold'}>Perfil Comercial</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant='h4'>
                                                    {totalViews}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography>Total de visualizaciones durante los ultimos 7 dias</Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item lg={6}>
                                    <Paper elevation={4}>
                                        <Grid container spacing={3} alignItems={'center'}>
                                            <Grid item lg={3} xl={3}>
                                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faQrcode}/>
                                                </Avatar>
                                            </Grid>
                                            <Grid item lg={9} xl={9}>
                                                <Typography variant="h6" fontWeight={'bold'}>Menu / Catalogo Digital</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant='h4'>
                                                    {totalViews}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography>Total de visualizaciones durante los ultimos 7 dias</Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Paper>
                                </Grid>
            
                                <Grid item lg={12}>
                                    <Paper elevation={4}>
                                    {
                                        dailyStatistics.length !== 0 &&
                                        <QUDAreaChart data={dailyStatistics} dataKeyX={'date'} dataKeyY={'total'} strokeColor="#147ac2" />
                                    }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} xl={4}>
                            <Paper elevation={4}>
                                <Grid container spacing={1} alignItems={'center'}>
                                    <Grid item lg={3} xl={3}>
                                        <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                            <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faTags}/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item lg={9} xl={9}>
                                        <Typography variant="h6" fontWeight={'bold'}>Productos mas vistos</Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            {
                                                topProducts.length !== 0 &&
                                                topProducts.map(item=>
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar>
                                                            <Avatar variant="rounded" sx={{width:60,height:60}} alt={item.product_name} src={`${imagesURL}/productos/${item.product_image}`} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            sx={{ml:1}}
                                                            primary={<Typography variant="h6">{item.product_name}</Typography>}
                                                            secondary={
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body1"
                                                                        sx={{ color: 'text.primary', display: 'inline' }}
                                                                    >
                                                                        {item.total_views} visualizaciones
                                                                    </Typography>
                                                                
                                                                </>
                                                            }
                                                        />
                                                    </ListItem>   
                                                )
                                                
                                            }
                                            
                                        </List>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography>Total de visualizaciones durante los ultimos 7 dias</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Paper>
                        </Grid>
                        
                    </Grid>
                </Grid>

                
            </Grid>

            

            
            
        </Container>
    )
}