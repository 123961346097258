import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function to_add()
{
    let response = await GET(CompanyRoutes.Tourism.GetToAdd);
    return response;
}

export async function add(category, name, description, history, city, address, latitude, longitude, image_cover,image_background)
{
    const params = [
        {name:'category',value:category},
        {name:'name',value:name},
        {name:'description',value:description},
        {name:'history',value:history},
        {name:'city',value:city},
        {name:'address',value:address},
        {name:'latitude',value:latitude},
        {name:'longitude',value:longitude},
        {name:'image_cover',value:image_cover},
        {name:'image_background',value:image_background}
    ];

    let response = await POST(CompanyRoutes.Tourism.Add,params);
    return response;
}

export async function getAll()
{
    let response = await GET(CompanyRoutes.Tourism.GetAll);
    return response;
}

export async function getPlaceByUUID(uuid)
{
    let response = await GET(CompanyRoutes.Tourism.GetPlace+uuid);
    return response;
}


export async function update(uuid,category, name, description, history, city, address, latitude, longitude, image_cover,image_background)
{
    const params = [
        {name:'category',value:category},
        {name:'name',value:name},
        {name:'description',value:description},
        {name:'history',value:history},
        {name:'city',value:city},
        {name:'address',value:address},
        {name:'latitude',value:latitude},
        {name:'longitude',value:longitude},
        {name:'image_cover',value:image_cover},
        {name:'image_background',value:image_background}
    ];

    let response = await POST(CompanyRoutes.Tourism.Update+uuid,params);
    return response;
}

export async function changeStatus(uuid)
{
    let response = await GET(CompanyRoutes.Tourism.ChangeStatus+uuid);
    return response;
}
