import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';



import { useHistory } from 'react-router-dom';


import { useTheme } from '@mui/material';



import "./homeStyles.css";
import Categories from './Categories';



import Carousel from 'react-elastic-carousel'
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faStore } from '@fortawesome/free-solid-svg-icons';

import logo from '../../assets/images/logos/qud4.png'
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import earlyAccessImg from '../../assets/images/website/Obten acceso anticipado 2.png';
import earlyAccessMobileImg from '../../assets/images/website/acceso_mobile.png';


const StyledCarousel = styled(Carousel)`
    
    .rec-dot_active{
        background-color: #bfebff;
        box-shadow: 0 0 1px 3px rgba(20, 122, 194,1);
    }

    .rec-arrow{
        background-color: #e3f6ff
    }

    .rec-arrow:hover {
        background-color: #147ac2
    }

    .rec-arrow:focus{
        background-color: #147ac2
    }
`;


export default function Home(props)
{
    const history = useHistory();
    const theme = useTheme();

    /*useEffect(()=>{
        redirect('/eventos')
    },[]);*/

    const redirect = (route) => {
        history.push(route);
    }

    const settings = {
        itemsToShow:1,
        //dots: false,
        infinite: true,
        enableAutoPlay: true,
        autoPlaySpeed: 5000,
        //speed:300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        showArrows: false,
        enableSwipe: true
    };



    return(
        <Box sx={{overflowX:'hidden'}}>
            <Container maxWidth='xl' sx={{ mt: 12 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box component={'a'} sx={{width:'100%','&:hover': { cursor: 'pointer' },display:{xs:'none',sm:'none',md:'none',lg:'block',lx:'block'} }} onClick={()=>redirect('auth/userType')}>
                            <img style={{width:'100%',borderRadius:20}} src={earlyAccessImg}/>
                        </Box>

                        <Box component={'a'} sx={{width:'100%','&:hover': { cursor: 'pointer' },display:{xs:'block',sm:'block',md:'block',lg:'none',lx:'none'} }} onClick={()=>redirect('auth/userType')}>
                            <img style={{width:'100%',borderRadius:20}} src={earlyAccessMobileImg}/>
                        </Box>
                        
                    </Grid>
                </Grid>
            </Container>


            
           
            {/**-------------------CATEGORIAS------------------- */}
            <br/>
            <Categories/>
            <br/>
        </Box>
    )
}


