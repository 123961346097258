
import WebsiteRoutes from "../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "./ConsumeApiController";

import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe('pk_test_tXggoq3rcB1QHynt7CZ3ZVg400NUsXP6Ue');

export async function CreatePaymentIntent(amount,merchant_id,customer) {
    const params = [
        {name:'amount',value:amount},
        {name:'merchant_id',value:merchant_id}
    ];

    let response = await POST_JSON(
        WebsiteRoutes.Stripe.createPaymentIntent,
        {
            amount,
            merchant_id,
            customer
        }
    );
    return response;
}

export async function createPayment()
{
    
}