import { useEffect, useState } from "react";
import { Avatar, Box, Button, Card, CardActionArea, CardContent, Container, Dialog, Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { imagesURL } from "../../../apiRoutes/apiBase";
import SectionTitle from "../../../components/sectionTitle";
import { GetMerchantCategories, GetMerchantProducts } from "../../../controllers/MerchantsController";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCartPlus, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ProfileImage from "../../../components/profileImage";
import QUDProgress from "../../../components/progress";
import MerchantMenuProductsDetails from "./menuProductsDetails";
import { createView } from "../../../controllers/customer/ViewsController";

export default function MerchantMenuProducts()
{
    const [height,setHeight] = useState(250);
    const [merchant,setMerchant] = useState([]);
    const [category,setCategory] = useState([]);
    const [products,setProducts] = useState([]);

    const [isLoading,setIsLoading] = useState(false);

    const [product,setProduct] = useState([]);
    const [open,setOpen] = useState(false);

    const {uuid,category_uuid} = useParams();
    const history = useHistory();

    useEffect(() => {
        handleGetProducts();
    },[]);

    const redireccionar = (route) => {
        history.push(route)
    }

    const handleGetProducts = async () => {
        setIsLoading(true);
        let response = await GetMerchantProducts(uuid,category_uuid);
        if(response.success === true)
        {
            setMerchant(response.data.merchant);
            setCategory(response.data.categoria);
            setProducts(response.data.productos);
            createView('category',response.data.categoria.idSubCategoria,response.data.merchant.id);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        createView('product',product.idProducto,product.merchant_id)
    },[product]);



    return(
        <Box>
            <Container sx={{pb:5,pt:12}}>
                <IconButton onClick={()=>{redireccionar('/merchants/'+uuid+'/menu')}}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </IconButton>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <Avatar
                        variant="rounded"
                        sx={{width:150,height:150}}
                        src={imagesURL+'empresas/'+merchant.imagen2}
                        alt={merchant.merchant}
                    />
                </Box>
                <Grid container spacing={3} mt={1} mb={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                            {merchant.merchant}
                        </Typography>
                        <Typography variant="h6" textAlign={'center'} mt={0}>{category.nombre}</Typography>
                    </Grid>
                </Grid>
                {/*<SectionTitle title={merchant.merchant} subtitle={category.nombre} mt={0}/>*/}
                
                <Grid container spacing={3}>
                {
                    products.length !== 0 &&
                    products.map(p=>
                        <Grid item xs={12} md={12} lg={4} xl={4}>
                            <Paper
                                elevation={3}
                                sx={{'&:hover':{bgcolor:'#f0f0f0',cursor:'pointer'}}}
                                onClick={()=>{setProduct(p);setOpen(true)}}
                            >
                                    <Box style={{display:'flex',flexDirection:'row',justifyContent:'space-between',height:85}} >
                                        <Box style={{display:'flex',flexDirection:'row',height:85}} >
                                            <Box style={{width:90,height:90}}>
                                                <img style={{width:90,height:90,borderRadius:10}} src={imagesURL+'productos/'+p.imagen} alt={p.nombre}/>
                                            </Box>
                                            <Box sx={{ml:1}}>
                                                <Typography style={{fontWeight:'bold'}}>{p.nombre} </Typography>
                                                <Typography style={{fontWeight:'bold'}} mb={1}>{p.precio} $/{p.unidad}</Typography>
                                                
                                            </Box>
                                        </Box>
                                        {/*<IconButton variant="outlined" size="small">
                                            <FontAwesomeIcon icon={faCartPlus}/>
                                        </IconButton>*/}
                                    </Box>
                            </Paper>
                        </Grid>
                    )
                }
                </Grid>
            </Container>

            <MerchantMenuProductsDetails
                open={open}
                onClose={()=>setOpen(false)}
                product={product}
            />

            <QUDProgress open={isLoading}/>
        </Box>
    )
}