import { useEffect, useState } from 'react';
import { Container, Box, Tab, Grid, Paper, Avatar, Typography, Button, IconButton, Divider, useTheme, Chip, Dialog, DialogTitle, DialogContent, styled, DialogActions } from '@mui/material';
import {TabContext, TabList, TabPanel }from '@mui/lab';
import CustomBreadcrumbs from '../../../components/breadcrumbs'
import SectionTitle from '../../../components/sectionTitle'

import Reservation from './reservation';
import ResponseComponent from '../../../components/responseComponent';
import ApprovedReservations from './approved';
import QUDProgress from '../../../components/progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClose, faEnvelope, faInfoCircle, faPhone, faPhoneAlt, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { approve, getAllReservations } from '../../../controllers/company/ReservationsController';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';



  


export default function CompanyReservations()
{
    const [value, setValue] = useState('1');
    const [toBeApproved,setToBeApproved] = useState([]);
    const [reservations,setReservations] = useState([]);
    const [selectedReservation,setSelectedReservation] = useState([]);
    const [openDialogReservation,setOpenDialogReservation] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const [open,setOpen] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState();
    const [actionText,setActionText] = useState('');

    const theme = useTheme();
    const history = useHistory();

    useEffect(()=>{
        handleGetToBeApproved();
    },[])

    const redirect = (route) => {
        history.push(route)
    }
    


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleGetToBeApproved = async () => {
        setIsLoading(true);
        const response = await getAllReservations();
        if(response.success === true)
        {
            setReservations(response.data.reservations);
        }
        setIsLoading(false);
    }

    const handleApproveReservation = async (uuid,status) => {
        //setOpenDialogReservation(false);
        setIsLoading(true);
        let response = await approve(uuid,status);
        if(response.success === true)
        {
            setTitle('Operación Exitosa!');
            setSuccess(true);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);
        }
        else
        {
            setTitle('Operación Fallida!');
            setSuccess(false);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);
        }
        setIsLoading(false);
    }

    const primaryAction = () => {
        setOpen(false);
        handleGetToBeApproved();
    }

    const handleDateClick = (arg) => {
        alert(arg.dateStr)
    }

    const handleReservationClick = (_reservation) => {
        setSelectedReservation(_reservation);
        setOpenDialogReservation(true);
    }

    const handleCloseReservation = () => {
        setOpenDialogReservation(false);
    }
    

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Reservaciones',link:'reservations'}]}/>
            
            <br/>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xl={12}>
                                <Typography variant="h4" component={'h1'} fontWeight={'bold'} gutterBottom>
                                    <FontAwesomeIcon icon={faCalendarAlt} color={theme.palette.link.main} />
                                    &nbsp;Panel de reservaciones
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                        {/**ESTADISTICAS AQUI */}
                        <Grid container spacing={3} mt={2} mb={2}>
                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3} alignItems={'center'}>
                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                        <FontAwesomeIcon color={theme.palette.link.main} size='2xl' icon={faInfoCircle}/>
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography variant="h6" component={'h2'} fontWeight={'bold'}>
                                            Actualmente nos encontramos trabajando para mostrarte datos estadísticos sobre las reservaciones que has recibido
                                        </Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        reservations.length !== 0
                        ?
                        <FullCalendar
                            plugins={[ dayGridPlugin ]}
                            initialView="dayGridMonth"
                            eventBackgroundColor='#FFF'
                            eventBorderColor='#FFF'
                            //weekends={false}
                            
                            events={reservations}
                            eventContent={renderEventContent}
                            eventClick={(info) => handleReservationClick(info.event._def.extendedProps)}
                        />
                        :
                        <Typography textAlign={'center'}>NO HAY RESERVACIONES</Typography>
                    }
                    
                </Grid>
            </Grid>

            <Dialog
                open={openDialogReservation}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth={'md'}
                onClose={()=>handleCloseReservation()}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseReservation}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 15,
                        top: 15,
                        color: theme.palette.grey[500],
                    })}
                    >
                    <FontAwesomeIcon icon={faClose}/>
                </IconButton>
                <DialogTitle display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Avatar
                        alt={selectedReservation.customer}
                        sx={{width:70,height:70}}
                        src={`${imagesURL}/users/${selectedReservation.customer_image}`}
                    />
                    <Box ml={2}>
                        <Typography variant='h6' fontWeight={'bold'}>{selectedReservation.customer}</Typography>
                        <IconButton color='link' href={`tel:${selectedReservation.customer_phone_number}`}>
                            <FontAwesomeIcon size='xs' icon={faPhone}/>
                        </IconButton>
                        <IconButton color='link' LinkComponent={'a'} target='_blank' href={`https://wa.me/${selectedReservation.customer_phone_number}`}>
                            <FontAwesomeIcon icon={faWhatsapp}/>
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{display:'flex',flexDirection:'row'}}>
                                <Typography>Personas: </Typography>
                                <Typography fontWeight={'bold'} ml={1}>{selectedReservation.people}</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box sx={{display:'flex',flexDirection:'row'}}>
                                <Typography>Fecha: </Typography>
                                <Typography fontWeight={'bold'} ml={1}>{selectedReservation.reservation_date}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box sx={{display:'flex',flexDirection:'row'}}>
                                <Typography>Hora: </Typography>
                                <Typography fontWeight={'bold'} ml={1}>{selectedReservation.time}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                <Typography>Observaciones: </Typography>
                                <Typography fontWeight={'bold'}>
                                    {selectedReservation.observations}
                                </Typography>
                            </Box>
                        </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        selectedReservation.status === 'Por Aprobar' &&
                        <>
                            <Button
                                variant='contained'
                                color='cancel'
                                onClick={()=>handleApproveReservation(selectedReservation.uuid,0)}
                            >
                                <Typography>Rechazar</Typography>
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={()=>handleApproveReservation(selectedReservation.uuid,2)}
                            >
                                <Typography>Aprobar</Typography>
                            </Button>
                        </>
                    }
                    {
                        selectedReservation.status === 'Aprobado' &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={()=>handleApproveReservation(selectedReservation.uuid,3)}
                        >
                            <Typography>Finalizar Reservacion</Typography>
                        </Button>
                    }
                    
                </DialogActions>
            </Dialog>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}

                onClick={()=>primaryAction()}
                actionText={actionText}
            />
        </Container>
    )
}


function renderEventContent(eventInfo)
{
    console.log('VAmos a pintar el eventos',eventInfo)
    const reservation = eventInfo.event._def.extendedProps
    let color = '#FFF';
    if(reservation.status === 'Por Aprobar')
        color='warning';
    else if(reservation.status === 'Aprobado')
        color = 'success';
    else if(reservation.status === 'Finalizado')
        color = 'link';
    else
        color = 'error'
    return(
        <Chip
            sx={{'&:hover':{cursor:'pointer'}}}
            label={`${reservation.time} ${reservation.customer}`}
            color={color}
        />
    )
}


/**
 * <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Por Revisar" value="1" />
                    <Tab label="Aprobadas" value="2" />
                    <Tab label="Historico" value="3" />
                </TabList>
                </Box>
                <TabPanel value="1">
                    <Grid container spacing={3}>
                        {
                            toBeApproved.length !== 0
                            ?
                            toBeApproved.map(item=>
                                <Reservation
                                    uuid={item.uuid}
                                    customer={item.customer}
                                    people={item.people}
                                    date={item.date}
                                    time={item.time}
                                    observations={item.observations}
                                    reject={()=>handleApproveReservation(item.uuid,0)}
                                    approve={()=>handleApproveReservation(item.uuid,2)}
                                />
                            )
                            :
                            <Typography mt={5}>No se encontraron resultados</Typography>
                        }
                        <QUDProgress open={isLoading}/>
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <ApprovedReservations/>
                </TabPanel>
                <TabPanel value="3"> <FontAwesomeIcon icon={faTriangleExclamation} size='2x'/>&nbsp; Historico de reservaciones en construcción</TabPanel>
            </TabContext>
 */