import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Avatar, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBan, faEdit, faGift, faPlay, faSmile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { GetProductos } from '../../../controllers/company/ProductsController';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';

//export default class TablaProductos extends Component
export default function TransactionsDataTable(props)
{
    const transactions = props.transactions;

    const columns = [
        {
            name: 'create_real_at',
            label: 'Fecha',
            options:
            {
                filter: false
            }
        },
        {
            name : 'gateway_id',
            label : 'plataforma'
        },
        {
            name : 'observations',
            label : 'Observaciones',
            options:{
                filter:false,
                searchable:true
            }
        },
        {
            name : 'reference',
            label : 'Referencia',
            options:{
                filter:false,
                searchable:true
            }
        },
        {
            name : 'amount',
            label : 'Monto',
            options:{
                filter:false,
                //filterType:'dropdown',
                searchable:true
            }
        },
        {
            name : 'transaction_type_id',
            label : 'Tipo',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => {
                    if(value === 1 || value === 4)
                        return(
                            <Avatar sx={{bgcolor:'success.main'}}>
                                <FontAwesomeIcon icon={faArrowUp}/>
                            </Avatar>
                        )
                    else
                        return(
                            <Avatar sx={{bgcolor:'error.main'}}>
                                <FontAwesomeIcon icon={faArrowDown}/>
                            </Avatar>
                        )
                }
            }
        },
        
    ];

  


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={"Listado de Transacciones"}
            data={transactions}
            columns={columns}
            options={options}
        />
    )
}