import { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getAll } from "../../../controllers/company/TourismController";
import PlacesDataTable from "./dataTable";

export default function Tourism()
{
    const [places,setPlaces] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetAllPlaces();
    },[]);

    const handleGetAllPlaces = async () => {
        setIsLoading(true);
        let response = await getAll();
        if(response.success === true)
        {
            setPlaces(response.data)
        }
        setIsLoading(false);
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Sitios Turísticos',link:'tourism'}]}/>
            <br/>
            <SectionTitle title='Sitios Turísticos'/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                        variant='contained'
                        endIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                        onClick={()=>redirect('/tourism/add')}
                    >
                        <Typography fontWeight={'bold'}>Sitios Turísticos</Typography>
                    </Button>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        places.length !== 0 &&
                        <PlacesDataTable places={places}/>
                    }
                </Grid>
            </Grid>
        </Container>
    )
}