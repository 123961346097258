import CustomerRoutes from "../../apiRoutes/CustomerRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function GetAllCoupons()
{
    let response = await GET(CustomerRoutes.Coupons.GetAll);
    return response;
}

export async function getDataToObtainAFreeCoupon(uuid)
{
    let response = await GET(CustomerRoutes.Coupons.PreloadedCoupon+uuid);
    return response
}