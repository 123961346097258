import { useState, useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import AddButton from "../../../components/addButton";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { GetAllFromMerchant } from "../../../controllers/company/CouponsController";
import CardInfo from "../../../components/cardInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faGift, faSmile } from "@fortawesome/free-solid-svg-icons";
import CouponsDataTable from "./dataTable";

export default function Coupons()
{
    const [coupons,setCoupons] = useState([]);
    const [statistics,setStatistics] = useState([]);

    useEffect(()=>{
        handleGetCoupons();
    },[])

    const handleGetCoupons = async () => {
        let response = await GetAllFromMerchant();
        if(response.success === true)
        {
            setCoupons(response.data.coupons);
            setStatistics(response.data.statistics);
        }
    }

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Cupones',link:'/coupons'}]}/>
            <SectionTitle title='Cupones'/>
            <Grid container spacing={3}>
                {
                    statistics.length !== 0 &&
                    statistics.map(item=>{
                        let icon;
                        let color;
                        if(item.status === 'Anulados')
                        {
                            icon = <FontAwesomeIcon icon={faBan}/>
                            color= 'error.main'
                        }
                            
                        else if(item.status === 'Emitidos')
                        {
                            icon = <FontAwesomeIcon icon={faGift}/>
                            color='primary.main'
                        }
                            
                        else
                        {
                            icon = <FontAwesomeIcon icon={faSmile}/>
                            color= 'success.main'
                        }
                            
                        return(
                            <CardInfo
                                title={item.status}
                                value={item.total}
                                color={color}
                                icon={icon}
                            />
                        )
                    })
                }
            </Grid>
            <br/>
            <AddButton title='Emitir cupón' route='/coupons/add'/>
            <br/>
            {
                coupons.length !== 0 &&
                <CouponsDataTable coupons={coupons}/>
            }
        </Container>
    )
}