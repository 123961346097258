import { useEffect, useState } from "react"
import { Box, Button, Container, Divider, Grid, IconButton, Link, MenuItem, Paper, TextField, ThemeProvider, Typography, useTheme } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookF, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";


import img1 from '../../assets/images/contactUs2.jpg';
import img2 from '../../assets/images/opcion2.jpg';
import { SendEmailFromContactUsForm } from "../../controllers/AuxController";
import QUDProgress from "../../components/progress";
import ResponseComponent from "../../components/responseComponent";
import { useTranslation } from "react-i18next";

const style={
    "& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)": {
        border:'solid',
        borderWidth:'1px',
        borderColor: "#CCC",
        color:'#CCC',
      },
    "& .MuiInputLabel-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)": {
        color: "#CCC",
        bgcolor:'primary.main',
        pl:1,pr:1,
    },
    "& .MuiInputBase-input": {
        color: "#CCC",
    },
}


export default function ContactUs2(props)
{
    const {t, i18n} = useTranslation();
    const theme = useTheme('dark');

    const [nombre,setNombre] = useState('');
    const [apellido,setApellido] = useState('');
    const [telefono,setTelefono] = useState('');
    const [email,setEmail] = useState('');
    const [servicio,setServicio] = useState('');
    const [ciudad,setCiudad] = useState('');
    const [mensaje,setMensaje] = useState('');

    const [errorNombre,setErrorNombre] = useState(false);
    const [errorApellido,setErrorApellido] = useState(false);
    const [errorEmail,setErrorEmail] = useState(false);
    const [errorTelefono,setErrorTelefono] = useState(false);
    const [errorServicio,setErrorServicio] = useState(false);
    const [errorCiudad,setErrorCiudad] = useState(false);
    const [errorMensaje,setErrorMensaje] = useState(false);


    const [isLoadingData,setIsLoadingData] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');


    const formVerification = () => {
        var ban = 0;
        
        if(nombre === '')
        {
            setErrorNombre(true);
            ban = 1;
        }

        if(apellido === '')
        {
            setErrorApellido(true);
            ban = 1;
        }

        if(email === '')
        {
            setErrorEmail(true);
            ban = 1;
        }

        if(telefono === '')
        {
            setErrorTelefono(true);
            ban = 1;
        }

        if(servicio === '')
        {
            setErrorServicio(true);
            ban = 1;
        }

        if(ciudad === '')
        {
            setErrorCiudad(true);
            ban = 1;
        }

        if(ciudad === '')
        {
            setErrorMensaje(true);
            ban = 1;
        }
        
        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;

        setIsLoadingData(true);
        let response = await SendEmailFromContactUsForm(nombre,apellido,email,telefono,servicio,ciudad,mensaje);
        if(response.success === true)
        {
            setTitle('Operación Exitosa');
            setMessage(response.message);
            setActionText('Finalizar');
            setSuccess(true);
            setShow(true);
        }
        setIsLoadingData(false);
    }

    const handleSuccess = () => {

        setNombre('');      setErrorNombre(false);
        setApellido('');    setErrorApellido(false);
        setEmail('');       setErrorEmail(false);
        setTelefono('');    setErrorTelefono(false);
        setServicio('');    setErrorServicio(false);
        setCiudad('');      setErrorCiudad(false);
        setMensaje('');     setErrorMensaje(false);

        setShow(false);

    }

    return(
        <Box>
            <QUDProgress open={isLoadingData}/>
            <ResponseComponent
                show={show}
                title={title}
                message={message}
                success={success}
                actionText={actionText}
                onClick={()=> handleSuccess()}
            />

            <Box sx={{bgcolor:'primary.main',pt:10,pb:10}}>
                <Container maxWidth='lg'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4' fontSize={45} component={'h1'} fontWeight={'bold'} color={'primary.contrastText'} gutterBottom >
                                {props.title}
                            </Typography>
                            <Typography variant='h6' component={'p'} color={'primary.contrastText'} gutterBottom >
                                {props.description}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        label={t('forms.name')}
                                        id="nombre"
                                        name="nombre"
                                        value={nombre}
                                        onChange={(e)=>setNombre(e.target.value)}
                                        onBlur={ () => (nombre === '' ? setErrorNombre(true) : setErrorNombre(false) ) }
                                        error = {errorNombre}
                                        helperText={errorNombre && 'Ingrese su nombre'}
                                        sx={style}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        label={t('forms.lastName')}
                                        id="apellido"
                                        name="apellido"
                                        value={apellido}
                                        onChange={(e)=>setApellido(e.target.value)}
                                        onBlur={ () => (apellido === '' ? setErrorApellido(true) : setErrorApellido(false) ) }
                                        error = {errorApellido}
                                        helperText={errorApellido && 'Ingrese su apellido'}
                                        sx={style}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        label={t('forms.email')}
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                        onBlur={ () => (email === '' ? setErrorEmail(true) : setErrorEmail(false) ) }
                                        error = {errorEmail}
                                        helperText={errorEmail && 'Ingrese su correo electrónico'}
                                        sx={style}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        label={t('forms.phoneNumber')}
                                        id="telefono"
                                        name="telefono"
                                        value={telefono}
                                        onChange={(e)=>setTelefono(e.target.value)}
                                        onBlur={ () => (telefono === '' ? setErrorTelefono(true) : setErrorTelefono(false) ) }
                                        error = {errorTelefono}
                                        helperText={errorTelefono && 'Ingrese su numero de teléfono'}
                                        sx={style}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        id="servicio"
                                        name="servicio"
                                        label={t('forms.subject')}
                                        variant="outlined"
                                        value={servicio}
                                        onChange={(e)=>setServicio(e.target.value)}
                                        onBlur={ () => (servicio === '' ? setErrorServicio(true) : setErrorServicio(false) ) }
                                        error = {errorServicio}
                                        helperText={errorServicio && 'Ingrese el Asunto'}
                                        sx={[
                                            style,
                                            {
                                                "& .MuiSelect-icon":
                                                {
                                                    color: "#ccc"
                                                }
                                            }
                                        ]}
                                        
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        label={t('forms.city')}
                                        id="ciudad"
                                        name="ciudad"
                                        value={ciudad}
                                        onChange={(e)=>setCiudad(e.target.value)}
                                        onBlur={ () => (ciudad === '' ? setErrorCiudad(true) : setErrorCiudad(false) ) }
                                        error = {errorCiudad}
                                        helperText={errorCiudad && 'Ingrese la ciudad de donde nos escribe'}
                                        sx={style}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        color="link"
                                        fullWidth
                                        multiline rows={4}
                                        label={t('forms.message')}
                                        id="mensaje"
                                        name="mensaje"
                                        value={mensaje}
                                        onChange={(e)=>setMensaje(e.target.value)}
                                        onBlur={ () => (mensaje === '' ? setErrorMensaje(true) : setErrorMensaje(false) ) }
                                        error = {errorMensaje}
                                        helperText={errorMensaje && 'Ingrese el mensaje'}
                                        sx={style}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'}>
                                    <Button
                                        color="cancel"
                                        disabled={isLoadingData}
                                        variant="contained"
                                        onClick={()=>handleSubmit()}
                                    >
                                        <Typography fontWeight={'bold'}>{props.buttonText || 'Enviar Mensaje'}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Divider/>
        </Box>
    )
}

/**
 *  <Box 
                sx={{
                    position:'relative',
                    height:300,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    bgcolor:'primary.main',
                    zIndex:-1
                }}
            >
                <Typography variant='h2' component='h2' color={'primary.contrastText'} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'},fontWeight:'bold'}}>
                    CONTÁCTANOS
                </Typography>
                <Typography variant='h4' component='h2' color={'primary.contrastText'} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'},fontWeight:'bold'}}>
                    CONTÁCTANOS
                </Typography>
            </Box>
            <Container sx={{mt:-10}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Paper elevation={4} sx={{padding:0}}>
                            <Grid container spacing={3}>
                                
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <Box sx={{pl:4,pb:3,pr:4}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant="h5" sx={{fontWeight:'bold',color:'primary.main'}}>
                                                    Empecemos
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Nombre'
                                                    id="nombre"
                                                    name="nombre"
                                                    value={nombre}
                                                    onChange={(e)=>setNombre(e.target.value)}
                                                    onBlur={ () => (nombre === '' ? setErrorNombre(true) : setErrorNombre(false) ) }
                                                    error = {errorNombre}
                                                    helperText={errorNombre && 'Ingrese su nombre'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Apellido'
                                                    id="apellido"
                                                    name="apellido"
                                                    value={apellido}
                                                    onChange={(e)=>setApellido(e.target.value)}
                                                    onBlur={ () => (apellido === '' ? setErrorApellido(true) : setErrorApellido(false) ) }
                                                    error = {errorApellido}
                                                    helperText={errorApellido && 'Ingrese su apellido'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Correo Electrónico'
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e)=>setEmail(e.target.value)}
                                                    onBlur={ () => (email === '' ? setErrorEmail(true) : setErrorEmail(false) ) }
                                                    error = {errorEmail}
                                                    helperText={errorEmail && 'Ingrese su correo electrónico'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Teléfono'
                                                    id="telefono"
                                                    name="telefono"
                                                    value={telefono}
                                                    onChange={(e)=>setTelefono(e.target.value)}
                                                    onBlur={ () => (telefono === '' ? setErrorTelefono(true) : setErrorTelefono(false) ) }
                                                    error = {errorTelefono}
                                                    helperText={errorTelefono && 'Ingrese su numero de teléfono'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    id="servicio"
                                                    name="servicio"
                                                    label="Servicio"
                                                    variant="outlined"
                                                    value={servicio}
                                                    onChange={(e)=>setServicio(e.target.value)}
                                                    onBlur={ () => (servicio === '' ? setErrorServicio(true) : setErrorServicio(false) ) }
                                                    error = {errorServicio}
                                                    helperText={errorServicio && 'Seleccione un servicio'}
                                                >
                                                    <MenuItem value='Eventos' >Eventos</MenuItem>
                                                    <MenuItem value='Marketplace'>Marketplace</MenuItem>
                                                    <MenuItem value='Cupones'>Cupones</MenuItem>
                                                    <MenuItem value='Puntos de Lealtad'>Puntos de Lealtad</MenuItem>
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Ciudad'
                                                    id="ciudad"
                                                    name="ciudad"
                                                    value={ciudad}
                                                    onChange={(e)=>setCiudad(e.target.value)}
                                                    onBlur={ () => (ciudad === '' ? setErrorCiudad(true) : setErrorCiudad(false) ) }
                                                    error = {errorCiudad}
                                                    helperText={errorCiudad && 'Ingrese la ciudad de donde nos escribe'}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline rows={4}
                                                    label='Mensaje'
                                                    id="mensaje"
                                                    name="mensaje"
                                                    value={mensaje}
                                                    onChange={(e)=>setMensaje(e.target.value)}
                                                    onBlur={ () => (mensaje === '' ? setErrorMensaje(true) : setErrorMensaje(false) ) }
                                                    error = {errorMensaje}
                                                    helperText={errorMensaje && 'Ingrese el mensaje'}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                                <Button
                                                    disabled={isLoadingData}
                                                    variant="contained"
                                                    onClick={()=>handleSubmit()}
                                                >
                                                    <Typography fontWeight={'bold'}>Enviar Mensaje</Typography>
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>

                                
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{bgcolor:'primary.main',borderBottomRightRadius:10,borderTopRightRadius:10,position:'relative'}}>
                                    <Box sx={{pl:4,pb:3,pr:4,color:'primary.contrastText',position:'relative',zIndex:100}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography variant="h5" fontWeight={'bold'}>Datos de Contacto</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography fontWeight={'bold'}><FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp;&nbsp;Lechería, Anzoátegui. Venezuela</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography fontWeight={'bold'}><FontAwesomeIcon icon={faPhone} />&nbsp;+58 424-8136335</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography fontWeight={'bold'}><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;contact@qud.life</Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Button
                                                    variant="text"
                                                    startIcon={<FontAwesomeIcon icon={faInstagram}/>}
                                                    sx={{color:'primary.contrastText',textTransform:'none',ml:-0.5}}
                                                    href="https://instagram.com/qud.life" target="_blank"
                                                >
                                                    <Typography fontWeight={'bold'}>qud.life</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        
                                    </Box>
                                    <Box
                                        sx={{
                                            width:'100%',height:'100%',
                                            display:'flex',
                                            //justifyContent:'center',
                                            alignItems:'center',
                                            backgroundImage: "url(" + img1 + ")",
                                            backgroundPosition:'center',
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'cover',
                                            opacity:0.1,
                                            position:'absolute',
                                            bottom:0, left:0,top:0,
                                            borderTopRightRadius:10,
                                            borderBottomRightRadius:10
                                        }}
                                    ></Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
 */