import React, { useRef } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Lottie from "lottie-react";
import successAnimation from '../assets/lottie/success2.json';
import errorAnimation from '../assets/lottie/error.json';

/**
 * @prop {string} title 
 * @prop {boolean} success
 * @prop {string} message
 * @prop {string} actionText
 * @prop {function} onClick
 * @prop {string} secondaryText
 * @prop {function} secondaryAction
 */
export default function ResponseComponent(props)
{
    const animation = useRef(null);

    return(
        <Dialog
            open={props.show}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={props.onClick}
        >
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                {
                    props.success === true
                    ?
                    <Lottie
                        autoPlay
                        style={{
                            width: 200,
                            height: 200,
                        }}
                        loop={false}
                        animationData={successAnimation}
                    />
                    :
                    <Lottie
                        autoPlay
                        style={{
                            width: 150,
                            height: 150,
                        }}
                        animationData={errorAnimation}
                    />
                }
            </Box>
            <DialogTitle textAlign={'center'} fontWeight={'bold'}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <Typography variant='h6' textAlign={'center'}>
                    {props.message}
                </Typography>
            </DialogContent>
            <DialogActions>
                {
                    (props.success === true && props.secondaryText !== undefined) &&
                    <Button
                        variant="outlined"
                        onClick={props.secondaryAction}
                    >
                        {props.secondaryText}
                    </Button>
                }
                <Button
                    variant="contained"
                    color={props.success === true ? 'link' : 'error'}
                    onClick={props.onClick}
                >
                    {props.actionText}
                </Button>
            </DialogActions>
        </Dialog>
    )

}