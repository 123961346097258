import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import tastyFestLogo from '../../../../assets/images/allies/tastyfest/TF6.png';
import tasty1 from '../../../../assets/images/allies/tastyfest/tasty1.png';
import tasty2 from '../../../../assets/images/allies/tastyfest/tasty2.png';
import tasty3 from '../../../../assets/images/allies/tastyfest/tasty3.png';
import sponsor from '../../../../assets/images/allies/tastyfest/sponsor.jpeg';
import banner from '../../../../assets/images/allies/tastyfest/banner.webp';
import hamburger1 from '../../../../assets/images/allies/tastyfest/Hamburger1.jpeg';
import mustard from '../../../../assets/images/allies/tastyfest/mustard.svg';
import TextQUD from "../../../../components/text";
import { getSponsors } from "../../../../controllers/customer/EventosController";
import { imagesURL } from "../../../../apiRoutes/apiBase";

import { motion } from "framer-motion"

//#bc0901 ROJO
//#ffab00 Mostaza
const tastyFest = {
    colors:{
        red: '#bc0901',
        mustard: '#ffab00',
        contrastText: '#FFF'
    }
}
export default function Hamburger()
{
    const [sponsors,setSponsors] = useState([]);

    useEffect(()=>{
        handleGetSponsors();
    },[]);

    const handleGetSponsors = async () => {
        let response = await getSponsors(6);
        if(response.success === true)
        {
            setSponsors(response.data);
        }
    }


    return(
        <Box sx={{overflowX:'hidden'}}>
            {/**Portada */}
            <Box sx={{position:'relative',bgcolor:'primary.main',minHeight:'100vh'}}>
                {/**Portada */}Tasty Fest
                <Box
                    sx={{
                        width:'100%',height:'100%',
                        display:'flex',
                        //justifyContent:'center',
                        alignItems:'center',
                        backgroundImage: "url(" + hamburger1 + ")",
                        backgroundPosition:'center',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'cover',
                        opacity:0.4,
                        position:'absolute',
                        bottom:0, left:0,top:0,
                    }}
                ></Box>
                <Box sx={{position:'relative'}}>
                    <Container sx={{mt:7}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography color={'#FFF'} variant="h4" textAlign={'center'}>
                                    ¡La <span style={{color:tastyFest.colors.mustard,fontWeight:'bold'}}>competencia<br/>del sabor</span> llegó a<br/>la ciudad!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                <Box sx={{width:700,mt:{xs:-10,lg:-25,xl:-25}}}>
                                    <motion.div
                                        initial={{ opacity: 0,translateX:-200 }}
                                        whileInView={{ opacity: 1,translateX:0 }}
                                        viewport={{ once: false }}
                                    >
                                        <img style={{width:'100%'}} src={tastyFestLogo} alt="Tast Fest"/>
                                    </motion.div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                        <Box
                            sx={{
                                background: 'linear-gradient(to right bottom, transparent 50%, #ffab00 50%)',
                                /* Los colores #f00 y #00f son solo ejemplos. Cambia estos colores según tus preferencias */
                                width: '100%',
                                height: 100 /* Ajusta la altura según tus necesidades */
                            }}
                        >
                        </Box>
                        <Box sx={{bgcolor:tastyFest.colors.mustard,paddingTop:10,paddingBottom:10,paddingLeft:3,paddingRight:3}}>
                            <Container maxWidth='lg'>
                                <Grid container spacing={3}>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography sx={{transform:'rotate(-4deg)'}} color={'#FFF'} fontWeight={'bold'} textAlign={'center'} variant="h4">
                                            Si te gusta comer, tienes un negocio<br/>
                                            de comida o <span style={{color:tastyFest.colors.red}}>te apasiona el mundo<br/>gastronómico</span>; vas a disfrutar mucho<br/>
                                            esta dinámica que hemos<br/>
                                            <span style={{color:tastyFest.colors.red,fontSize:50}}>creado para ti.</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                        
                    </Box>
                </Box>
            </Box>

            {/**Pagina 2 */}
            <Box sx={{bgcolor:tastyFest.colors.mustard,position:'relative',minHeight:'100vh',width:'100%',paddingBottom:10}}>
                <Container maxWidth='md' sx={{zIndex:3,position:'relative'}}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <img style={{width:300}} src={tastyFestLogo} alt="Tasty Fest"/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography color={tastyFest.colors.contrastText} fontWeight={'bold'} variant="h2">
                                ¿De qué<br/>se trata?
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                text={
                                    <>
                                        Tasty Fest es una <span style={{color:tastyFest.colors.red, fontWeight:'bold'}}>dinámica de<br/>
                                        competitividad</span> entre marcas <br/>
                                        gastronómicas.
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        Nace para <span style={{color:tastyFest.colors.red, fontWeight:'bold'}}>elevar el nivel gastronómico</span><br/>
                                        en nuestros alrededores, con una<br/>
                                        contienda sana que hará que cada local<br/>
                                        de <span style={{color:tastyFest.colors.red, fontWeight:'bold'}}>lo mejor de sí </span>
                                        en cada plato.
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        Quien obtenga la mayoría de los votos,<br/> 
                                        obtendrá una <span style={{color:tastyFest.colors.red, fontWeight:'bold'}}>placa identificativa</span>,<br/>
                                        material publicitario...
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        Quien obtenga la mayoría de los votos,<br/> 
                                        obtendrá una <span style={{color:tastyFest.colors.red, fontWeight:'bold'}}>placa identificativa</span>,<br/>
                                        material publicitario...
                                    </>
                                }
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Box
                    sx={{
                        width:'100%',height:'100%',
                        display:'flex',
                        //justifyContent:'center',
                        alignItems:'center',
                        backgroundImage: "url(" + tasty1 + ")",
                        backgroundPosition:'right',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'contain',
                        opacity:{
                            xs:0.3,
                            sm:0.3,
                            md:0.3,
                            lg:1,
                            xl:1
                        },
                        position:'absolute',
                        top:0, right:0,
                    }}
                />
            </Box>

            {/**Pagina 3 */}
            <Box sx={{bgcolor:tastyFest.colors.mustard,position:'relative',minHeight:'100vh',width:'100%',paddingBottom:10}}>
                <Box sx={{position:'relative'}}>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',mb:-10}}>
                        <img style={{width:300}} src={tastyFestLogo} alt="Tasty Fest"/>
                    </Box>
                    <Box sx={{bgcolor:tastyFest.colors.red,position:'relative'}}>
                        <Box
                            sx={{
                                background: 'linear-gradient(to left top, #bc0901 50%, #ffab00 50%)',
                                /* Los colores #f00 y #00f son solo ejemplos. Cambia estos colores según tus preferencias */
                                width: '100%',
                                height: 100 /* Ajusta la altura según tus necesidades */
                            }}
                        />
                        <Container maxWidth='md' sx={{paddingBottom:10,position:'relative',zIndex:3}}>
                            <Grid container spacing={3} justifyContent={'center'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Typography color={tastyFest.colors.contrastText} fontWeight={'bold'} variant="h2">
                                        El Paso a Paso:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        text={
                                            <>
                                                <span style={{color:tastyFest.colors.mustard}}>1. </span>
                                                Anunciamos a través de nuestros canales: @thetastyfest<br/>la fecha y el rubro de la próxima competencia
                                            </>
                                        }
                                    />

                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        mt={5}
                                        text={
                                            <>
                                                <span style={{color:tastyFest.colors.mustard}}>2. </span>
                                                Los negocios podrán inscribirse para participar.
                                            </>
                                        }
                                    />

                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        mt={5}
                                        text={
                                            <>
                                               <span style={{color:tastyFest.colors.mustard}}>3. </span>
                                                Se establecerá un costo por el platillo que se vaya a<br/>degustar esa semana
                                            </>
                                        }
                                    />

                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        mt={5}
                                        text={
                                            <>
                                               <span style={{color:tastyFest.colors.mustard}}>4. </span>
                                                Los negocios tendrán un código QR que pondrán a<br/>
                                                disposición de todos los que quieran ir a consumir el<br/>
                                                producto de la semana para participar en la votación.
                                            </>
                                        }
                                    />

                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        mt={5}
                                        text={
                                            <>
                                               <span style={{color:tastyFest.colors.mustard}}>5. </span>
                                                Anunciaremos todas las marcas participantes en<br/>
                                                nuestros medios y en los canales de los generadores<br/>
                                                de contenido aliados.
                                            </>
                                        }
                                    />

                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        mt={5}
                                        text={
                                            <>
                                               <span style={{color:tastyFest.colors.mustard}}>6. </span>
                                                Los comensales tendrán un lapso de una semana<br/>
                                                para probar la mayoría de los platillos que participan<br/>
                                                y asi aumentar las votaciones hasta elegir un ganador.
                                            </>
                                        }
                                    />

                                    <TextQUD
                                        color={tastyFest.colors.contrastText}
                                        mt={5}
                                        text={
                                            <>
                                               <span style={{color:tastyFest.colors.mustard}}>7. </span>
                                                Las marcas participantes tendrán acceso al sistema<br/>
                                                para mayor transparencia en el conteo.
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        
                    </Box>
                    <Box
                        sx={{
                            width:'100%',minHeight:'100%',
                            display:'flex',
                            //justifyContent:'center',
                            alignItems:'center',
                            backgroundImage: "url(" + tasty2 + ")",
                            //backgroundPosition:'center',
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'cover',
                            opacity:{
                                xs:0.5,
                                sm:0.5,
                                md:0.5,
                                lg:1,
                                xl:1
                            },
                            position:'absolute',
                            top:-115, left:0,
                        }}
                    />
                </Box>
                
            </Box>

            {/**Pagina 4*/}
            <Box sx={{bgcolor:tastyFest.colors.mustard,paddingBottom:0}}>
                <Container maxWidth='md'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                            <img style={{width:300}} src={tastyFestLogo} alt="Tasty Fest"/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                            <Typography variant="h3" component={'h1'} textAlign={'center'} color={tastyFest.colors.contrastText} fontWeight={'bold'}>
                                ¿Qué <span style={{color:tastyFest.colors.red}}>beneficios</span><br/>
                                tienes <span style={{color:tastyFest.colors.red}}>como marca</span><br/>
                                al participar?
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>1. </span>
                                        La exposición publicitaria desde el anuncio del evento
                                        en canales de comunicación y cuentas aliados
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>2. </span>
                                        El flujo de comensales dispuestos a visitar el lugar
                                        y compartir la experiencia
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>3. </span>
                                        La posibilidad de ganar como el mejor platillo y obtener
                                        mayor alcance en el mercado
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>4. </span>
                                        Contenido pre y post evento como participante y en el
                                        caso de resultar ganador.
                                    </>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'} mt={-100}>
                            <img style={{width:1000}} src={tasty3} alt="Tasty Fest"/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            {/**Pagina 5*/}
            <Box sx={{bgcolor:tastyFest.colors.mustard,paddingBottom:10}}>
                <Container maxWidth='md'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                            <img style={{width:300}} src={tastyFestLogo} alt="Tasty Fest"/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                            <Typography variant="h2" component={'h1'} textAlign={'center'} color={tastyFest.colors.contrastText} fontWeight={'bold'}>
                                ¿Cómo empezamos?
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>1. </span>
                                        La incripción para tu local tiene un costo de 80$<br/>
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>2. </span>
                                        El evento está pautado para la última semana de octubre,
                                        iniciando la campaña publicitaria a mediados<br/> 
                                        del mes de septiembre
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>3. </span>
                                        Te entregamos un sticker que identificará a tu negocio<br/>
                                        como participante
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>4. </span>
                                        Te ofreceremos el acceso al sistema de votación y<br/>
                                        generaremos un QR para que cada comensal pueda votar.
                                    </>
                                }
                            />

                            <TextQUD
                                color={tastyFest.colors.contrastText}
                                mt={5}
                                text={
                                    <>
                                        <span style={{color:tastyFest.colors.red}}>5. </span>
                                        Te asesoramos en cuanto al producto y experiencia que<br/>
                                        quieras ofrecer, adaptando a tu marca
                                    </>
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'} mt={-100}>
                            <img style={{width:1000}} src={tasty3} alt="Tasty Fest"/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{paddingBottom:10}}>
                <img style={{width:'100%'}} src={banner} alt="banner tasty fest"/>
                <Container maxWidth='md'>
                    <Grid container spacing={3} justifyContent={'center'}>
                        {
                            sponsors.length !== 0 &&
                            sponsors.map(item=>
                                <Grid item xs={4} sm={4} md={3} lg={2} xl={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Box
                                        sx={{
                                            width:120,
                                            height:'auto',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                            '&:hover':{cursor:'pointer'}
                                        }}
                                        component={'a'}
                                        href={item.url}
                                        target={'_blank'}
                                    >
                                        <img style={{width:'100%'}} src={imagesURL+'eventos/patrocinadores/'+ item.ruta_imagen}/>
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}