import { Switch } from "react-router-dom";
import AdminLayout from "../layouts/DashboardAdmin/adminLayout";


import AppRoute from "./AppRoute";
import Home from "../views/admin";
import TourismCategories from "../views/admin/tourism/categories";
import ChangePassword from "../views/auth/changePassword";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom";





export default function AdminNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={AdminLayout} component={Home} />

            {/**SETTINGS */}
            <AppRoute exact path='/settings' layout={AdminLayout} component={Home} />
            <AppRoute exact path='/settings/tourism/categories' layout={AdminLayout} component={TourismCategories} />

            {/**SECURITY */}
            <AppRoute exact path='/security/changepassword' layout={AdminLayout} component={ChangePassword} />

            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}