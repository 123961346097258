import { useReducer } from "react";
import { LoadingButton } from "@mui/lab";
import { Container, Divider, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import moment from "moment";
import ModalDialog from "../../../components/modalDialog";
import { useHistory } from "react-router-dom";
import { Add } from "../../../controllers/company/AssociatesController";


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    email: '',      errorEmail: false,
    position:'',      errorPosition: false,
    
    isLoading : false,

    open: false,
    titleR: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }


export default function AssociatesAdd()
{
    const [state,setState] = useReducer(reducer,initialState);

    const {
        email, errorEmail, position, errorPosition,
        isLoading, open, titleR, message, success
    } = state;

    const history = useHistory();


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const formVerification = () => {
        var ban = 0;

        if(email === '')
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        if(position === '')
        {
            setState({field:'errorPosition',value:true});
            ban = 1;
        }
        
        return ban;
    }

    

    const handleSubmit = async () => {
        setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            let response = await Add(email,position);

            if(response.success === true)
            {
                setState({field:'titleR',value:'Operacion Exitosa!'});
                setState({field:'message',value:response.message});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'titleR',value:'Operacion Fallida!'});
                setState({field:'message',value:response.message});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
        }
        setState({field:'isLoading',value:false});
    }

    return(
        <Container spacing={3}>
            <CustomBreadcrumbs
                returnTo='/associates'
                crumbs={[
                    {name:'Asociados',link:'/associates'},
                    {name:'Agregar',link:'/associates/add'}
                ]}
            />
            <SectionTitle title='Agregar Asociado' />

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Title */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='Correo electronico'
                                id='email'
                                name='email'
                                value= {email}
                                onChange= {onChange}
                                onBlur= {()=> email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) }
                                error= {errorEmail}
                                helperText={errorEmail && 'Debe ingresar el correo electronico'}
                            />  
                        </Grid>

                        {/**Amount */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label='Cargo'
                                id='position'
                                name='position'
                                value= {position}
                                onChange= {onChange}
                                onBlur= {()=> position === '' ? setState({field:'errorPosition',value:true}) : setState({field:'errorPosition',value:false}) }
                                error= {errorPosition}
                                helperText={errorPosition && 'Debe ingresar el título'}
                            />
                        </Grid>

                        

                        

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton variant="contained" fullWidth
                                loading={isLoading}
                                onClick={()=>handleSubmit()}
                            >
                                <Typography>Agregar Asociado</Typography>
                            </LoadingButton>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>

            <ModalDialog
                open={open}
                title={titleR}
                msg={message}

                close='Cerrar'
                handleClose={()=>success === true ? history.push('/associates') : setState({field:'open',value:'false'})}
                
                action='Aceptar'
                handleAction={()=>success === true ? history.push('/associates') : setState({field:'open',value:'false'})}
            />
        </Container>
    )
}