import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { imagesURL } from "../apiRoutes/apiBase";
import { useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";

import Carousel from 'react-elastic-carousel'
import styled from "styled-components";


const StyledCarousel = styled(Carousel)`
    
    .rec-dot_active{
        background-color: #bfebff;
        box-shadow: 0 0 1px 3px rgba(20, 122, 194,1);
    }

    .rec-arrow{
        background-color: #e3f6ff
    }

    .rec-arrow:hover {
        background-color: #147ac2
    }

    .rec-arrow:focus{
        background-color: #147ac2
    }
`;

export default function ProductsCarousel(props)
{
    const [open,setOpen] = useState(false);
    const [product,setProduct] = useState([]);

    const settings = {
        itemsToShow:4,
        dots: false,
        infinite: true,
        //enableAutoPlay: true,
        speed:300,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: true,
        breakPoints :[
            { width: 1, itemsToShow: 1, itemsToScroll: 1,pagination:false},
            { width: 550, itemsToShow: 3, itemsToScroll: 3 },
            { width: 850, itemsToShow: 4, itemsToScroll: 4 },
            { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
            { width: 1450, itemsToShow: 5, itemsToScroll: 5 },
            { width: 1750, itemsToShow: 6, itemsToScroll: 36 },
        ],
        
    };


    const handleProduct = (item) => {
        setProduct(item);
        setOpen(true);
    }

    return(
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
                sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',mb:5,paddingBottom:3.3}}
            >
                <Typography variant="h4" sx={{fontWeight:'bold'}}>Productos destacados</Typography>
                <Link style={{textDecoration: 'none'}} to={'/merchants/'+props.merchant_uuid+'/menu'}>
                    <Typography color='#0076B6' variant='body1' sx={{textDecorationLine:'none'}}>
                        Ver todos
                    </Typography>
                </Link>

            </Box>
            
            <Box sx={{mb:5}}>
                <StyledCarousel
                    {...settings}
                    dotsColor='#000'
                >
                    {
                        props.items.map(item=>
                            <div>
                                <Box 
                                style={{
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    width:'100%',
                                }}
                                >
                                    <Box 
                                        sx={{
                                            width:150,
                                            height:150,
                                            borderRadius:10,
                                            borderColor:'#000',
                                            background:'#000',
                                            '&:hover':{cursor:'pointer'}
                                        }}
                                        onClick={()=>handleProduct(item)}
                                    >
                                        <img
                                            style={{width:150,height:150,borderRadius:10}}
                                            src={imagesURL+'productos/'+item.imagen}
                                            alt={item.nombre}
                                        />
                                    </Box>
                                    <Typography>{item.nombre}</Typography>
                                </Box>
                            </div>
                        )
                    }
                </StyledCarousel>
            </Box>

            <Dialog
                open={open}
                fullWidth
                maxWidth='md'
                onClose={()=>setOpen(false)}
            >
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                    <IconButton aria-label="delete" onClick={()=>setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>
                    <Grid item lg={4} xl={4}>
                        <Box>
                            <img
                                style={{width:'100%',height:'auto',borderRadius:10}}
                                src={imagesURL+'productos/'+product.imagen}
                                alt={product.nombre}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={8} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Typography variant="h5">{product.nombre}</Typography>    
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Typography variant="h5">{product.precio} $</Typography>    
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1">{product.descripcion}</Typography>    
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    )
}