import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Menu, Typography, Container, 
    Button, Drawer, ListItem, List, Divider, Link, MenuItem, Tooltip, Avatar, 
    ListItemIcon, FormControl, FormLabel, FormHelperText ,
    InputAdornment, OutlinedInput, InputLabel
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useHistory } from 'react-router-dom';


import './ScrollToColor'

import logo from '../../assets/images/logos/logoQUD.png';
import ScrollToColor from './ScrollToColor';
import UserContext from '../../navigation/context/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faHome, faSearch, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AuthContext from '../../navigation/context/authContext';
import Searcher from '../../components/searcher';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const ResponsiveAppBar = (props) => {
    const { userData } = useContext(UserContext);
    const { signOut } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const [show,setShow] = useState(false);
    const location = useLocation();

    const {t, i18n} = useTranslation();

    useEffect(()=>{
        handleShowSearcher();
    },[location]);

    const handleShowSearcher = () => {
        //console.log('PATH_NAME ===> ', location.pathname);
        let path = location.pathname;
        
        path = path.split('/');
        if(path[1] === 'auth' || path[1] === 'ticketing' || path[1] === 'artemis')
            setShow(false);
        else if(path.length > 3 && path[3] === 'menu')
            setShow(false);
        else
            setShow(true);
    }
   
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const history = useHistory()
    const redirect = (route) => {
        history.push(route);
        setDrawerIsOpen(false)
    }
  

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setDrawerIsOpen(open);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

 

    return (
        <ScrollToColor>
            <AppBar position="fixed" color="transparent" elevation={0}>
                <Container maxWidth="xl" >
                    <Toolbar component='div' disableGutters sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Button onClick={()=>history.push('/')} sx={{display:{xs:'none',md:'flex'}}}>
                            <img style={{width:145}} src={logo} alt='QUD' />
                        </Button>

                        <Button onClick={()=>history.push('/')} sx={{display:{xs:'flex',md:'none'}}}>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ mr: 5,flexGrow:1}}
                            >
                                <img style={{width:150}} src={logo} alt='QUD' />
                            </Typography>
                        </Button>

                        <Box sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'},width:'60%'}}>
                        {/*
                            show &&
                            <Searcher/>
                        */}
                        </Box>

                        <Box sx={{ flexGrow: 0, display:{xs:'flex',md:'flex'},alignItems:'center' }}>
                            {
                                /**Este bloque esta trabajando bien */
                                userData.user !== undefined
                                ?
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar>
                                                <FontAwesomeIcon icon={faUser}/>
                                            </Avatar>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {/**
                                        <MenuItem>
                                            <ListItemIcon>
                                                <DashboardIcon/>
                                            </ListItemIcon>
                                            Dashboard
                                        </MenuItem> */}
                                        
                                        <MenuItem onClick={()=>redirect('/')}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faHome}/>
                                            </ListItemIcon>
                                            <Typography variant='h6' sx={{textTransform:'none'}}>
                                                Inicio
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={()=>handleSignOut()}>
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={faSignOut}/>
                                            </ListItemIcon>
                                            <Typography variant='h6' sx={{textTransform:'none'}}>
                                                Salir
                                            </Typography>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                                :
                                <Box sx={{display:{xs:'none',sm:'none',md:'flex'}}}>

                                    <Button
                                        onClick={()=> redirect('/') }
                                        sx={{ my: 2, color:'primary.contrastAux', display: 'block' }}
                                    >
                                        <Typography fontWeight={'bold'}>
                                            Inicio
                                        </Typography>
                                    </Button>

                                    <Button
                                        onClick={()=> redirect('/auth/signIn') }
                                        sx={{
                                            my: 2,
                                            ml:2,
                                            display:{xs:'none',sm:'none', md:'block',lg:'block',xl:'block'},
                                            color:'primary.contrastAux'
                                        }}
                                        variant='outlined'
                                    >
                                        <Typography fontWeight={'bold'}>
                                            Inicia sesión
                                        </Typography>
                                    </Button>
                                </Box>
                            }
                            {/*<MyApp mode={props.mode}/>*/}
                        </Box>

                        {
                            userData.user === undefined &&
                            <>
                                <Box sx={{ flexGrow: 0, display:{xs:'flex',md:'none'},alignItems:'center' }}>
                                    <IconButton 
                                        onClick={ toggleDrawer(true) }
                                        //color="primary.contrastAux"
                                        sx={{color:'primary.contrastAux'}}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                </Box>
                        
                                <Drawer
                                    anchor='right'
                                    open={drawerIsOpen}
                                    onClose={toggleDrawer(false)}
                                >
                                    <div style={{width:250}}>
                                        <List style={{paddingLeft:10,paddingRight:10}}>
                                            <ListItem>
                                                <Button onClick={()=>redirect('/')}>
                                                    <Typography variant='h6' sx={{textTransform:'none'}}>
                                                        Inicio
                                                    </Typography>
                                                </Button>
                                            </ListItem>
                                            
                                            <Divider/>
                                            
                                            <ListItem>
                                                <Button onClick={()=>redirect('/auth/signIn')}>
                                                    <Typography variant='h6' sx={{textTransform:'none'}}>
                                                        Iniciar Sesión
                                                    </Typography>
                                                </Button>
                                            </ListItem>

                                            <ListItem>
                                                <Button fullWidth href='https://qud.life/auth/userType' target='_blank' variant='contained'>
                                                    <Typography variant='h6' sx={{textTransform:'none'}}>
                                                        Registrate
                                                    </Typography>
                                                </Button>
                                            </ListItem>
                                        </List>
                                    </div>
                                </Drawer>
                            </>
                        }
                       
                    </Toolbar>
                </Container>
                <Container maxWidth={'lg'} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'},width:'100%'}}>
                    {/*
                        show &&
                        <Searcher/>
                    */}
                </Container>
            </AppBar>
           
        </ScrollToColor>
    );
};
export default ResponsiveAppBar;
