import WebsiteRoutes from "../apiRoutes/WebsiteRoutes";
import { GET, POST } from "./ConsumeApiController";
import moment from "moment";

export async function getCiudades()
{
    let response = await GET(WebsiteRoutes.Ciudades.GetAll);
    return response;
}

export async function getCategoriasEvento()
{
    let response = await GET(WebsiteRoutes.Eventos.Categorias.GetAll);
    return response;
}



export function today()
{
    let date = moment().format('YYYY-MM-DD');
    return date;
}


//send email from contact us form
export async function SendEmailFromContactUsForm(nombre,apellido,email,telefono,servicio,ciudad,mensaje)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'apellido',value:apellido},
        {name:'email',value:email},
        {name:'telefono',value:telefono},
        {name:'servicio',value:servicio},
        {name:'ciudad',value:ciudad},
        {name:'mensaje',value:mensaje}
    ];

    const response = await POST(WebsiteRoutes.Faqs.ContactUsForm,params);
    return response;
}

//export function getDate()



export function cortarPalabra(palabra, longitud) {
    if(palabra.length > longitud)
    {
        return palabra.substring(0, longitud - 3) + "...";
    }
    else
    {
        return palabra;
    }
}

export function isNumeric(str)
{
    return /^\d+$/.test(str);
}

export function formatStringWithHyphens(str) {
    return str.trim().replace(/\s+/g, '-');
}