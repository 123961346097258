import { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { getAll } from "../../../controllers/customer/ReservationsController";
import ReservationsDataTable from "./dataTable";
import QUDProgress from "../../../components/progress";


export default function ClientReservations()
{
    const [reservations,setReservations] = useState([]);

    const [isLoading, setIsLoading]  = useState(false);

    useEffect(()=>{
        handleGetReservations();
    },[]);

    const handleGetReservations = async () => {
        setIsLoading(true);
        let response = await getAll();
        console.log(response.data)
        if(response.success === true)
        {
            setReservations(response.data)
        }
        setIsLoading(false);
    }

    return(
        <Container sx={{pt:10}}>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Reservaciones',link:'/reservations'}]}/>
            <br/>
            <SectionTitle title={'Reservaciones'}/>
            {
                reservations.length !== 0
                ?<ReservationsDataTable reservations={reservations}/>
                :<Typography variant="body1" textAlign={'center'}>No se encontraron resultados</Typography>
            }
            <QUDProgress open={isLoading}/>
        </Container>
    )
}