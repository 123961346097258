import { useContext, useEffect, useReducer, useState } from "react";
import { Box, Button, Card, CardActionArea, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import UserContext from "../../navigation/context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import GoogleLogin from "react-google-login";
import { gapi } from 'gapi-script';
import AuthContext from "../../navigation/context/authContext";
import SignIn from "./signIn";
import PersonSignUp from "./signUp_person";
import SocialAuth from "./SocialAuth";

//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
      
    email : '', errorEmail : false,
    password : '', errorPassword : false,

    isLoading : false,
    errorSignIn : false,
    errorSignInMsg : '',

    isOpen : false,
    title:'',
    msg:''
}

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}

export default function SignInModal(props)
{
    const {userData} = useContext(UserContext);
    const { signIn } = useContext(AuthContext);
    const [open,setOpen] = useState(props.open);
    const [activeStep,setActiveStep] = useState(0);

    const [state,setState] = useReducer(reducer,initialState);
    const {
        email, errorEmail,
        password, errorPassword,

        isLoading,

        isOpen,
        title,
        msg,

    } = state;


    useEffect(()=>{
        if(userData.user === undefined)
            setOpen(true);
    },[]);

    useEffect(()=>{
        function start() {
            gapi.client.init({
              clientId: '617727461618-03sc7bftdp5bmh6cnjeftp23nuf5ad6i.apps.googleusercontent.com',
              scope: 'email',
            });
          }
      
          gapi.load('client:auth2', start);
    },[]);

    const googleSuccess = async (res) => {
        console.log(res.profileObj)
        setState({field:'isLoading',value:true});
        const email = res.profileObj.email;
        const password = res.profileObj.googleId;
        const oAuth = true;
        await signIn({email,password,oAuth});
            //redirect('/')
        
        setState({field:'isLoading',value:false});
    };

    const googleFailure = (error) => {
        console.log(error);
        console.log("Google Sing In ha fracasado intentelo denuevo mas tarde");
    };

    return(
        <Dialog
            //open={open}
            open={props.open}
            maxWidth='sm'
            fullWidth
            fullScreen={activeStep!== 0}
            //onClose={()=>setOpen(false)}
            onClose={props.onClose}
        >
            {
                activeStep === 0 &&
                <DialogTitle textAlign={'center'}>Es necesario iniciar sesión para continuar</DialogTitle>
            }
            <DialogContent sx={{padding:0}}>
                {
                    activeStep !== 0 &&
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <IconButton onClick={()=>setActiveStep(0)}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                        </IconButton>
                    </Grid>
                }
                {
                    activeStep === 0 &&
                    <Grid container spacing={3} justifyContent={'center'} p={1}>
                        
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                            <SocialAuth/>
                            {/*<GoogleLogin
                                style={{width:'100%'}}
                                clientId="617727461618-03sc7bftdp5bmh6cnjeftp23nuf5ad6i.apps.googleusercontent.com"
                                buttonText="Continuar con Google"
                                onSuccess={googleSuccess}
                                onFailure={googleFailure}
                                //cookiePolicy={'single_host_origin'}
                            />*/}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Card sx={{p:0}}>
                                <Button color="cancelDark" onClick={()=>setActiveStep(1)}>
                                    <Box sx={{height:100,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <FontAwesomeIcon size="3x" icon={faUser}/>
                                        <Typography mt={1}>Ya tengo un usuario</Typography>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Card sx={{p:0}}>
                                <Button color="cancelDark" onClick={()=>setActiveStep(2)}>
                                    <Box sx={{height:100,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <FontAwesomeIcon size="3x" icon={faUser}/>
                                        <Typography mt={1}>Registrar un usuario</Typography>
                                    </Box>
                                </Button>
                            </Card>
                           
                        </Grid>

                        
                    </Grid>
                }
                {
                    activeStep === 1 &&
                    
                        <SignIn/>
                    
                }
                {
                    activeStep === 2 &&
                    
                        <PersonSignUp stay={true}/>
                    
                }
                {
                    activeStep !== 0 &&
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} display={'flex'} justifyContent={'center'} alignItems={'center'} pb={2}>
                        <Button onClick={()=>setActiveStep(0)}>
                            Volver
                        </Button>
                    </Grid>
                }
            </DialogContent>
        </Dialog>
    )
}