import React, { useContext, useEffect, useState } from 'react';

import MUIDataTable from "mui-datatables";
import { Chip, Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket } from '@fortawesome/free-solid-svg-icons';


//export default class TablaProductos extends Component
export default function TicketsSoldDataTable(props)
{
    const [tickets,setEvents] = useState(props.tickets);
    

    const columns = [
        {
            name : 'codigo',
            label : 'Cod. Pedido',
            
            options:{
                filter:false,
                searchable:true
            }
        },
        /*{
            name : 'vendedor_nombre',
            label : 'Vendedor',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:true,
                searchable:true
            }
        },*/
        {
            name : 'cliente',
            label : 'Cliente',
            
            options:{
                filter:false,
                searchable:true
            }
        },
        {
            name : 'seccion',
            label : 'Sección',
            options:{
                filter:true,
                //filterType:'dropdown',
                display:true,
                searchable:true
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 'Disponible'
                    ? <Chip label='Disponible' color='success'/>
                    :
                    value === 'Usado' 
                    ?<Chip label='Usado' color='link' />
                    :<Chip label='Anulado' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Acciones',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <IconButton
                                onClick={()=>props.checkTicket(tickets[dataIndex].uuid,2)}
                            >
                                <Tooltip title='Editar' placement="top">
                                    <FontAwesomeIcon icon={faTicket}/>
                                </Tooltip>
                            </IconButton>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];



    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
        
            title={"Listado de boletos"}
            data={tickets}
            columns={columns}
            options={options}
        />
    )
}