import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Box } from "@mui/material";
import HomeAppBar from "./HomeAppBar";
import Footer from "../../../views/website/footer";


export function HomeLayout(props)
{
    useEffect(()=>{
        window.scrollTo(0,0);
    })



    return(
        <div>
            <HomeAppBar/>
            <Box sx={{pt:0,minHeight:'100vh'}}>
                {props.children}
            </Box>
            <Footer/>
        </div>
    )
}