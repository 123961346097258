import { faEllipsis, faHandshakeAngle, faHeartPulse, faHotel, faSpa, faStore, faTicket, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Typography, Grid, Paper, Box, Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//imagenes
import icono_eventos from '../../assets/images/website/icono_eventos.png';
import icono_gastronomia from '../../assets/images/website/icono_gastronomia.png';
import icono_hospedaje from '../../assets/images/website/icono_hospedaje.png';
import icono_tiendas from '../../assets/images/website/icono_tiendas.png';
import icono_salud from '../../assets/images/website/icono_salud.png';
import icono_belleza from '../../assets/images/website/icono_belleza.png';
import icono_servicios from '../../assets/images/website/icono_servicios.png';
import icono_transporte from '../../assets/images/website/icono_transporte.png';
import { useTranslation } from 'react-i18next';

function CategoryButton(props)
{
    return(
        <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
            <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                onClick={props.onClick}
            >
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={2} mb={2}>
                    <img style={{width:60}} src={props.icon} alt='QUD'/>
                    <Typography variant='h6' component={'p'} fontWeight={'bold'} mt={2}>{props.name}</Typography>
                </Box>
            </Paper>
        </Grid>
    )
}

export default function Categories(props)
{
    const {t, i18n} = useTranslation();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    return(
        <Container maxWidth='xl'>
            <Typography variant='h4' component={'h1'} textAlign={'center'} fontWeight={'bold'} mt={2} mb={2}>
                Explorar
            </Typography>
            
            <Grid container spacing={3} mb={5}>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                    <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                        onClick={()=>redirect('eventos')}
                    >
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={2} mb={2}>
                            <img style={{width:60}} src={icono_eventos} alt='eventos QUD'/>
                            <Typography variant='h6' component={'p'} fontWeight={'bold'} mt={2}>{t('categories.events')}</Typography>
                        </Box>
                    </Paper>
                </Grid>

                

                <CategoryButton
                    icon={icono_gastronomia}
                    name={t('categories.gastronomy')}
                    onClick={()=>redirect('search/Gastronomía')}
                />

                <CategoryButton
                    icon={icono_hospedaje}
                    name={t('categories.lodging')}
                    onClick={()=>redirect('search/Hospedaje')}
                />

                <CategoryButton
                    icon={icono_tiendas}
                    name={t('categories.shops')}
                    onClick={()=>redirect('search/Tiendas')}
                />

                <CategoryButton
                    icon={icono_salud}
                    name={t('categories.health')}
                    onClick={()=>redirect('search/Salud')}
                />

                <CategoryButton
                    icon={icono_belleza}
                    name={t('categories.beauty')}
                    onClick={()=>redirect('search/Belleza')}
                />

                <CategoryButton
                    icon={icono_servicios}
                    name={t('categories.services')}
                    onClick={()=>redirect('search/Servicios')}
                />

                <CategoryButton
                    icon={icono_transporte}
                    name={t('categories.transport')}
                    onClick={()=>redirect('search/Transporte')}
                />

                {/** 
                <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                    <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={4} mb={4}>
                            <FontAwesomeIcon size='2x' rotation={70} icon={faEllipsis}/>
                            <Typography variant='h6' component={'p'} fontWeight={'bold'} mt={2}>más</Typography>
                        </Box>
                    </Paper>
                </Grid>*/}
            </Grid>
        </Container>
    )
}