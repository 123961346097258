import { Typography, useMediaQuery, useTheme } from "@mui/material";

export default function TextQUD(props)
{
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

    if(matchesSm === false || matchesMd === false)
        return(
            <Typography
                variant="h5"
                component={'p'}
                color={props.color}
                gutterBottom
                {...props}
                sx={{display:'block'}}
            >
                {props.text}
            </Typography>    
        )
    else
        return(
            <Typography
                variant="h4"
                component={'p'}
                color={props.color}
                gutterBottom
                {...props}
            >
                {props.text}
            </Typography>
        )
}