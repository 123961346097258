import { useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, Rating, Typography } from "@mui/material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import StarIcon from '@mui/icons-material/Star';
import SignInModal from "../views/auth/signInModal";
import UserContext from "../navigation/context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { review_create } from "../controllers/customer/ReviewsController";
import ResponseComponent from "./responseComponent";
import QUDProgress from "./progress";

const labels = {
    1: 'Terrible',
    2: 'Malo',
    3: 'Normal',
    4: 'Bueno',
    5: 'Genial',
  };
  
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }




export default function Review(props)
{
    const {userData} = useContext(UserContext);
    const [value, setValue] = useState(3);
    const [hover, setHover] = useState(3);

    const [comment,setComment] = useState('');

    const [showModal,setShowModal] = useState(false);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState();
    const [actionText,setActionText] = useState('');
  

    const calculateUser = () => {
        if(userData.user === undefined)
        {
            localStorage.setItem('openModalReview','true');
            setShowModal(true);
        }
    }

    let type = '';
    if(props.type === 'merchant')
        type = 'Comercio';
    else if(props.type === 'product')
        type = 'Producto';
    else if(props.type === 'event')
        type = 'Evento';

    
    const handleOnClose = () => {
        localStorage.removeItem('openModalReview');
        setShowModal(false)
    }

    const primaryAction = () => {
        if(success === true)
        {
            setOpen(false);
            props.handleClose();
        }
        else
        {
            setOpen(false);
        }
    }

    
    const handleSubmit = async () => {
        setIsLoading(true);
        let response = await review_create(props.type,props.toUUID,value,comment);
        if(response.success === true)
        {
            setTitle('Operación Exitosa!');
            setSuccess(true);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);

            setComment('');
            setValue(3);
            setHover(3);
        }
        else
        {
            setTitle('Operación Fallida!');
            setSuccess(false);
            setMessage(response.message);
            setActionText('Finalizar');
            setOpen(true);
        }
        setIsLoading(false);
    }

    return(
        <Dialog
            open={props.open}
            //onClose={props.handleClose}
            fullWidth
        >
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                <IconButton onClick={props.handleClose}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            </Box>
            <DialogTitle>Reseñar {type}</DialogTitle>
            
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{display:'flex',flexDirecction:'row',justifyContent:'center',width:'100%'}}>
                            <img style={{width:200,borderRadius:10}} src={props.image}/>
                        </Box>
                        <Typography textAlign={'center'} fontWeight={'bold'} variant="h5">{props.name}</Typography>
                    </Grid>

                    {
                        userData.user !== undefined
                        ?
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box
                                    sx={{
                                        width:'100%',
                                        display: 'flex',
                                        flexDirection:'column',
                                        justifyContent:'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {value !== null && (
                                        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                                    )}
                                    <Rating
                                        name="hover-feedback"
                                        value={value}
                                        precision={1}
                                        sx={{fontSize:40}}
                                        getLabelText={getLabelText}
                                        onChange={(event, newValue) => {
                                            setValue(newValue);
                                        }}
                                        onChangeActive={(event, newHover) => {
                                            setHover(newHover);
                                        }}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    id="comment"
                                    name="comment"
                                    label="Comentario (opcional)"
                                    value={comment}
                                    onChange={(e)=>setComment(e.target.value)}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1" textAlign={'center'}>Para poder dejar una reseña debe iniciar sesión</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button variant="contained" fullWidth onClick={()=>calculateUser()}>
                                    <Typography fontWeight={'bold'}>Iniciar Sesión</Typography>
                                </Button>
                            </Grid>
                        </>
                    }
                    
                </Grid>
                
                
            </DialogContent>
            <DialogActions>
                {
                    userData.user !== undefined &&
                    <>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            color="cancel"
                            onClick={props.handleClose}
                        >
                            <Typography fontWeight={'bold'}>Cancelar</Typography>
                        </Button>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            onClick={()=>handleSubmit()}
                        >
                            <Typography fontWeight={'bold'}>Reseñar</Typography>
                        </Button>
                    </>
                }
                
            </DialogActions>
            
            
            <SignInModal
                open={showModal}
                onClose={()=>handleOnClose()}
            />

            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}

                onClick={()=>primaryAction()}
                actionText={actionText}
            />

            <QUDProgress open={isLoading}/>
            
        </Dialog>
    )
}