import { Avatar, Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { imagesURL } from "../apiRoutes/apiBase";


import RoomIcon from '@mui/icons-material/Room';

import { Facebook, Instagram, Phone, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faLocationDot, faMapLocation, faStar, faTags } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


export default function ProfileImage(props)
{

    return(
        <Container maxWidth='xl'>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection:'column',
                    //alignItems:'end',
                    minHeight:{xs:'38vh',sm:'40vh',md:'55vh',lg:'90vh',xl:'85vh'},
                    //maxHeight:{xs:'28vh',sm:'40vh',md:'55vh',lg:'75vh',xl:'75vh'},
                    //alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: {xs:1.5,sm:2,md:3,lg:3,xl:3},
                    backgroundImage:"url(" + imagesURL+'empresas/'+props.image + ")",
                    borderRadius:5,
                    //backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+city.imagen + ")",  
                    //backgroundImage: "linear-gradient(to bottom,rgba(33, 33, 33,0) 0%, rgba(33, 33, 33,0.95) 100%),url(" + imagesURL+'empresas/'+props.imagen + ")",
                    /*backgroundImage:{
                        xs:"linear-gradient(to bottom,rgba(0, 0, 0,0) 0%, rgba(0, 0, 0,1) 95%), url(" + imagesURL+'empresas/'+props.image + ")",
                        md:"linear-gradient(to bottom,rgba(1, 1, 1,0) 70%, rgba(0, 0, 0,1) 100%), url(" + imagesURL+'empresas/'+props.image + ")"
                    },*/
                    //"linear-gradient(to bottom,rgba(1, 1, 1,0) 70%, rgba(0, 0, 0,1) 100%), url(" + imagesURL+'empresas/'+props.image + ")"
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    position:'relative',
                    marginBottom:1,
                }}
            >
                
                    <Grid container spacing={3} sx={{flex:'auto'}}>
                        <Grid item lg={4} sx={{flex:'auto'}}>
                            <Paper sx={{height:'100%'}}>
                                <Grid container spacing={1} display={'flex'}>
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                            <Avatar
                                                variant="rounded"
                                                alt={props.merchant}
                                                src={ imagesURL+'empresas/'+props.logo}
                                                sx={{ width: 100, height: 100 }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h4" fontWeight={'bold'}>
                                            {props.merchant}
                                        </Typography>
                                        <Typography>({props.category})</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography>
                                            {props.description}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
                                        <Typography><FontAwesomeIcon icon={faMapLocation}/> {props.city}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography><FontAwesomeIcon icon={faStar}/> {parseFloat(props.average).toFixed(1)} ({props.reviews} reseñas)</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    startIcon={<FontAwesomeIcon icon={faWhatsapp}/>}
                                                    target='_blank'
                                                    href={"tel:"+props.user.telefono}
                                                >
                                                    Contactar
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    startIcon={<FontAwesomeIcon
                                                    icon={faMapLocation}/>}
                                                    target="_blank"
                                                    href={'http://maps.google.com/maps?daddr='+parseFloat(props.user.latitud)+','+parseFloat(props.user.longitud)}
                                                >
                                                    Indicaciones
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                <Button fullWidth variant="outlined" startIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}>
                                                    Reservar
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                <Button fullWidth variant="outlined" startIcon={<FontAwesomeIcon icon={faStar}/>}>
                                                    Reseñar
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                                                <Button fullWidth variant="outlined" startIcon={<FontAwesomeIcon icon={faTags}/>}>
                                                    Catálogo
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
            </Box>
        </Container>
    )
}


/**
 * return(
        <Box
                sx={{
                    display: 'flex',
                    flexDirection:'row',
                    alignItems:'end',
                    minHeight:{xs:'38vh',sm:'40vh',md:'55vh',lg:'85vh',xl:'85vh'},
                    //maxHeight:{xs:'28vh',sm:'40vh',md:'55vh',lg:'75vh',xl:'75vh'},
                    //alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p0: 3,
                //backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+city.imagen + ")",  
                    //backgroundImage: "linear-gradient(to bottom,rgba(33, 33, 33,0) 0%, rgba(33, 33, 33,0.95) 100%),url(" + imagesURL+'empresas/'+props.imagen + ")",
                    backgroundImage:{
                        xs:"linear-gradient(to bottom,rgba(0, 0, 0,0) 0%, rgba(0, 0, 0,1) 95%), url(" + imagesURL+'empresas/'+props.image + ")",
                        md:"linear-gradient(to bottom,rgba(1, 1, 1,0) 70%, rgba(0, 0, 0,1) 100%), url(" + imagesURL+'empresas/'+props.image + ")"
                    },
                    //"linear-gradient(to bottom,rgba(1, 1, 1,0) 70%, rgba(0, 0, 0,1) 100%), url(" + imagesURL+'empresas/'+props.image + ")"
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    position:'relative',
                    marginBottom:1,
                }}
            >
                <Container maxWidth='xl' sx={{mt:'38vh',pb:2}}>
                    <Grid container spacing={3}>
                        
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'}>
                                    <Typography variant="h4" fontWeight={'bold'} color={'primary.contrastText'}>
                                        {props.merchant}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography color={'primary.contrastText'}>
                                        {props.city}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={-1.5}>
                                    <Typography color={'primary.contrastText'}>({props.category})</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    
                                    <Box sx={{ml:3,display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        <Box sx={{display:'flex',flexDirection:'row'}}>
                                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                            <FontAwesomeIcon icon={faStar} color='#fcba03' />
                                            {/*<FontAwesomeIcon icon={faStar} color='#999999' />*
                                            </Box>
                                            <Typography variant="subtitle2" color={'primary.contrastText'}>
                                                {props.reviews} reseñas
                                            </Typography>
                                        </Box>
                                        <Avatar variant="rounded" sx={{bgcolor:'link.main',color:'#FFF',ml:3}} >
                                            <Typography variant="h4" fontWeight={'bold'}>{props.average}</Typography>
                                        </Avatar>
                                    </Box>
                            </Grid>
    
                            
                        </Grid>
                    </Container>
                </Box>
        )
 */