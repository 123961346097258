import { Redirect, Route, Switch } from "react-router-dom";
import CompanyLayout from "../layouts/dashboardEmpresa/companyLayout";
import ChangePassword from "../views/auth/changePassword";
import CompanyHome from "../views/company";
import BusinessCards from "../views/company/businessCards";
import AssociatesAdd from "../views/company/businessCards/add";
import Categories from "../views/company/categories";
import CategoriesAdd from "../views/company/categories/add";
import CategoriesUpdate from "../views/company/categories/update";
import Coupons from "../views/company/coupons";
import CouponsAdd from "../views/company/coupons/add";
import Products from "../views/company/products";
import ProductAdd from "../views/company/products/add";
import ProductUpdate from "../views/company/products/update";
import CompanyProfile from "../views/company/profile";
import Merchants from "../views/website/merchants";
import BusinessCard from "../views/website/merchants/businessCard";
import MerchantMenu from "../views/website/merchants/menu";
import MerchantMenuProducts from "../views/website/merchants/menuProducts";
import MerchantProfile from "../views/website/merchants/profile";
import MerchantSelect from "../views/website/merchants/select";

import AppRoute from "./AppRoute";
import Wallet from "../views/company/wallet";
import Pay from "../views/company/wallet/pay";
import DepositMethods from "../views/company/wallet/deposit/methods";
import Events from "../views/company/events";
import EventsAdd from "../views/company/events/add";
import EventsUpdate from "../views/company/events/update";
import TicketsTabs from "../views/company/events/tickets";
import MyBankAccounts from "../views/company/finances/banks";
import AddBankAccount from "../views/company/finances/banks/add";
import Orders from "../views/company/orders";
import Sponsors from "../views/company/events/sponsors";
import SponsorAdd from "../views/company/events/sponsors/add";
import Tourism from "../views/company/tourism";
import TourismAdd from "../views/company/tourism/add";
import TourismUpdate from "../views/company/tourism/update";
import CompanyReservations from "../views/company/reservations";
import Ticket from "../views/client/events/ticket";
import OrderDetails from "../views/company/orders/details";
import Artemis from "../views/taxi/Artemis";
import MamaAzul from "../views/website/allies/mamaazul";
import Reviews from "../views/company/reviews";
import ProductsV2 from "../views/company/products/index2";



export default function CompanyNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={CompanyLayout} component={CompanyHome} />

            <AppRoute exact path='/artemis' layout={CompanyLayout} component={Artemis} />
            <AppRoute exact path='/mamaazul' layout={CompanyLayout} component={MamaAzul} />

            <AppRoute exact path='/profile' layout={CompanyLayout} component={CompanyProfile} />
            <AppRoute exact path='/reviews' layout={CompanyLayout} component={Reviews} />

            <AppRoute exact path='/security/changepassword' layout={CompanyLayout} component={ChangePassword} />

            <AppRoute exact path='/categories' layout={CompanyLayout} component={Categories} />
            <AppRoute exact path='/categories/add' layout={CompanyLayout} component={CategoriesAdd} />
            <AppRoute exact path='/categories/update' layout={CompanyLayout} component={CategoriesUpdate} />

            <AppRoute exact path='/productsxxx' layout={CompanyLayout} component={Products} />
            <AppRoute exact path='/products' layout={CompanyLayout} component={Products} />
            <AppRoute exact path='/products/add' layout={CompanyLayout} component={ProductAdd} />
            <AppRoute exact path='/products/update' layout={CompanyLayout} component={ProductUpdate} />

            <AppRoute exact path='/orders' layout={CompanyLayout} component={Orders} />
            <AppRoute exact path='/orders/details/:code' layout={CompanyLayout} component={OrderDetails} />

            <AppRoute exact path='/coupons' layout={CompanyLayout} component={Coupons} />
            <AppRoute exact path='/coupons/add' layout={CompanyLayout} component={CouponsAdd} />


            <AppRoute exact path='/merchants' layout={CompanyLayout} component={Merchants} />
            <AppRoute exact path='/merchants/:uuid' layout={CompanyLayout} component={MerchantSelect} />
            <AppRoute exact path='/merchants/:uuid/menu' layout={CompanyLayout} component={MerchantMenu} />
            <AppRoute exact path='/merchants/:uuid/menu/:category_uuid' layout={CompanyLayout} component={MerchantMenuProducts} />
            <AppRoute exact path='/merchants/:uuid/profile' layout={CompanyLayout} component={MerchantProfile} />

            <AppRoute exact path='/associates' layout={CompanyLayout} component={BusinessCards} />
            <AppRoute exact path='/associates/add' layout={CompanyLayout} component={AssociatesAdd} />

            <AppRoute exact path='/businesscard/:uuid' layout={CompanyLayout} component={BusinessCard} />

            <AppRoute exact path='/events' layout={CompanyLayout} component={Events} />
            <AppRoute exact path='/events/add' layout={CompanyLayout} component={EventsAdd} />
            <AppRoute exact path='/events/update/:id' layout={CompanyLayout} component={EventsUpdate} />
            <AppRoute exact path='/events/tickets/:id' layout={CompanyLayout} component={TicketsTabs} />
            <AppRoute exact path='/events/sponsors/:id' layout={CompanyLayout} component={Sponsors} />
            <AppRoute exact path='/events/sponsors/:id/add' layout={CompanyLayout} component={SponsorAdd} />
            <AppRoute exact path='/ticketing/:uuid' layout={CompanyLayout} component={Ticket} />


            {/**Reservations */}
            <AppRoute exact path='/reservations' layout={CompanyLayout} component={CompanyReservations} />


            <AppRoute exact path='/tourism' layout={CompanyLayout} component={Tourism} />
            <AppRoute exact path='/tourism/add' layout={CompanyLayout} component={TourismAdd} />
            <AppRoute exact path='/tourism/update/:uuid' layout={CompanyLayout} component={TourismUpdate} />
            

            <AppRoute exact path='/finances/banks' layout={CompanyLayout} component={MyBankAccounts} />
            <AppRoute exact path='/finances/banks/add' layout={CompanyLayout} component={AddBankAccount} />

            <AppRoute exact path='/wallet' layout={CompanyLayout} component={Wallet} />
            <AppRoute exact path='/wallet/pay' layout={CompanyLayout} component={Pay} />
            <AppRoute exact path='/wallet/deposit/methods' layout={CompanyLayout} component={DepositMethods} />



            
            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}