import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { checkTicket, GetTicketsSoldByEvent } from "../../../../../controllers/company/EventsController";
import TicketsSoldDataTable from "./dataTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import QUDProgress from "../../../../../components/progress";
import ResponseComponent from "../../../../../components/responseComponent";

export default function TicketsSold(props)
{
    const {id} = useParams();
    const [event,setEvent] = useState(props.event);
    const [tickets,setTickets] = useState([]);

    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');
    const [title,setTitle] = useState('');

    /*open : false,
    result : false, message : '', title : '',*/

    useEffect(()=>{
        handleGetTicketsSold();
    },[]);

    const handleGetTicketsSold = async () => {
        let response = await GetTicketsSoldByEvent(id);
        if(response.success === true)
        {
            setTickets(response.data.tickets);
        }
    }

    const handleCheckTicket = async (uuid,status) => {
        setIsLoading(true);
        let response = await checkTicket(uuid,status);
        if(response.success === true)
        {
            setSuccess(true);
            setMessage(response.message);
            setTitle('Operación Exitosa');
            setOpen(true);
            handleGetTicketsSold();
        }
        else{
            setSuccess(false);
            setMessage(response.message);
            setTitle('Operación Fallida');
            setOpen(true);
        }
        setIsLoading(false);
    }

    const handleClose = () => {
        /*if(success === true){
            handleGetTicketsSold()
        }*/
        setOpen(false)
    }

    return(
        <Container>
            {
                tickets.length !== 0 &&
                <TicketsSoldDataTable tickets={tickets} checkTicket={handleCheckTicket}/>
            }
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={'Aceptar'}
                onClick={()=>handleClose()}
            />
        </Container>
    )
}