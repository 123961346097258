import React, { Component, useEffect, useReducer, useState } from 'react';
import {Map, Marker, GoogleApiWrapper, Polyline, InfoWindow, GoogleAPI} from 'google-maps-react';
import { Box, IconButton,Typography, Grid, Paper, TextField, Button, CircularProgress,
          Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, 
        } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import m1 from '../../../assets/images/markers/m1B.png';
import m2 from '../../../assets/images/markers/m2.png';

const containerStyle = {
    position: 'relative',  
    width: '100%',
    height: '100%'
}


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
  
  initialCenter : {lat:10.153135,lng:-64.678742},
  center : {lat : 10.153135, lng : -64.678742},
  
  origen : {lat:10.153135,lng:-64.678742},
  destino : {lat:10.153135,lng:-64.678742},
  tarifa : 0.6,
  distancia : 0,
  calcular : false,
  precioEstafeta : 1,
  precio : 0,
  montoDelivery : 0,
  ruta : [],

  dOrigen : '', errorDOrigen : false,
  dDestino : '', errorDDestino : false,
  descripcion : '', errorDescripcion : false,

  tasaCambio : 0,
  openDialog : false,

  pin: '', errorPin : false,

  progress : false,
  finalizado : false,

  DialogMessage : false, message : '', title : '',

  people:1
  
}

function reducer( state, { field, value })
{
  return{
      ...state,
      [field] : value
  }
}


export function MapContainer(props)
{
  const [state,dispatch] = useReducer(reducer,initialState);
  
  const {
    initialCenter, center, origen, destino, tarifa, distancia, calcular, precio, ruta,
    dOrigen, errorDOrigen, dDestino, errorDDestino, descripcion, errorDescripcion, tasaCambio,
    openDialog, pin, errorPin, progress, finalizado, precioEstafeta, montoDelivery,
    DialogMessage, message, title,
    people
  } = state


    var ORIGEN = {lat: origen.lat,lng:origen.lng}
    var DESTINO = {lat:destino.lat,lng:destino.lng}
    var TARIFA = 0;
    var PRECIO = 0;
    var RUTA = [];
    var DISTANCIA = 0;

    useEffect(()=>{
        
    },[]);


    const handleClickOpen = () => {
      dispatch({field:'openDialog',value:true})
    };
  
    const handleClose = () => {
      dispatch({field:'openDialog',value:false})
    };


    var rad = function(x) {
      return x * Math.PI / 180;
    };
    
    var getDistance = function(p1, p2) {
      var R = 6378137; // Earth’s mean radius in meter
      var dLat = rad(p2.lat - p1.lat);
      var dLong = rad(p2.lng - p1.lng);
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d/1000; // returns the distance in meter
    };


    /*const getTarifa = async () => {
      TARIFA = await GET(ROUTES.TARIFAS.GetTarifa+'Estafeta');
      
      dispatch({field:'tarifa',value:TARIFA.data.tarifa.valor});
    }

    const getPrecioEstafeta = async () => {
      let PRECIOESTAFETA = await GET(ROUTES.ESTAFETAS.Precio);
      PRECIOESTAFETA = parseFloat(PRECIOESTAFETA.data.precio);
      
      dispatch({field:'precioEstafeta',value:PRECIOESTAFETA});
    }*/



    const onMarkerDragEnd = async(t,map,coord, tipo) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        let coords = [];
        let waypoints = [];

        if(tipo === 'origen')
        {
            dispatch({field:'origen',value:{lat:lat,lng:lng}});
            ORIGEN = {lat:lat,lng:lng}
        }
            
        else
        {
                dispatch({field:'destino',value:{lat:lat,lng:lng}});
                DESTINO = {lat:lat,lng:lng}
        }
            const directionsService = new t.google.maps.DirectionsService();
            const directionsDisplay = new t.google.maps.DirectionsRenderer();

            //directionsDisplay.setMap(map);

            let request = {
            origin: ORIGEN,
            waypoints: [
                {
                location: {lat:ORIGEN.lat,lng:ORIGEN.lng},
                stopover: true,
                },
                {
                location: {lat:DESTINO.lat,lng:DESTINO.lng},
                stopover: true,
                },
            ],
            destination: DESTINO,
            travelMode: "DRIVING",
            optimizeWaypoints: true
            };


            await directionsService.route(request, async function (result, status) {
                if (status == "OK") {
                    
                    dispatch({field:'ruta',value:result.routes[0].overview_path});
                    RUTA = result.routes[0].overview_path;
                    DISTANCIA = getDistance(ORIGEN,DESTINO).toFixed(2);
                    dispatch({field:'distancia',value:DISTANCIA});
                }
            });
    };

    
    useEffect(()=>{
        CalcularPrecio();
    },[ruta])


    const CalcularPrecio = async () => {
          dispatch({field:'calcular',value:true});
          
          PRECIO = (parseFloat(distancia) * parseFloat(tarifa) );
    
          if(PRECIO < 1.50)
            PRECIO = 1.50
    
          dispatch({field:'montoDelivery',value:PRECIO});

          PRECIO = (PRECIO + precioEstafeta).toFixed(2);
          dispatch({field:'precio',value:PRECIO});
      
    }

    const handleValue = (op) => {
        if(op === '+')
        {
            if(people === 4)
                return;
            else
            dispatch({field:'people',value:people+1});
        }
        else
        {
            if(people === 1)
                return;
            else
            dispatch({field:'people',value:people-1});;
        }
    }



   

    return(
        <>
            <div style={{height:250,width:'100%',display:'flex',flexDirection:'row'}}>
                <Map 
                    google={window.google} 
                    zoom={14}

                    initialCenter={{
                        lat: initialCenter.lat,
                        lng: initialCenter.lng
                    }}
                    center = {{
                        lat: center.lat,
                        lng: center.lng
                    }}
                    
                    containerStyle={containerStyle}
                    //onClick={this.mapClicked}
                >
                    
                    <Marker
                        position={{lat: parseFloat(ORIGEN.lat), lng: parseFloat(ORIGEN.lng)}} 
                        title='Origen'
                        //color='#000'
                        style={{color:'#000'}}
                        draggable={true}
                        onDragend={(t, map, coord) => onMarkerDragEnd(t,map,coord,'origen')}
                        //image={{uri: m1}}
                        icon={{
                            url:m1
                        }}
                    />
                    

                    <Marker
                        title='Destino'
                        position={{lat: parseFloat(DESTINO.lat), lng: parseFloat(DESTINO.lng)}} 
                        draggable={true}
                        
                        onDragend={(t, map, coord) => onMarkerDragEnd(t,map,coord,'destino')}
                        icon={{
                            url:m2
                        }}
                    />

                    <Polyline
                        path={ruta}
                    />
                    
                </Map>
            </div>
            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-around',mt:2}}>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <FontAwesomeIcon icon={faLocationDot}/>
                    <Typography ml={1}> Origen</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <FontAwesomeIcon color='#CC0000' icon={faLocationDot}/>
                    <Typography ml={1}> Destino</Typography>
                </Box>
            </Box>
            {/**People */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',bgcolor:'#ededed',borderRadius:2,p:2}}>
                    <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                        <Box>
                            <Typography variant='h5' fontWeight={'bold'}>$ {precio}</Typography>
                            <Typography variant='body1'>Tarifa</Typography>
                        </Box>
                        <Box>
                            <Typography variant='subtitle1'>Distancia = {distancia} km</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <IconButton color="primary" aria-label="minus" onClick={()=>handleValue('-')}>
                                <FontAwesomeIcon icon={faMinusCircle}/>
                            </IconButton>
                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',ml:1,mr:1}}>
                                <Typography variant="h5" fontWeight={'bold'}>
                                    {people}
                                </Typography>
                                
                            </Box>
                            <IconButton color="primary" aria-label="plus" onClick={()=>handleValue('+')}>
                                <FontAwesomeIcon icon={faPlusCircle}/>
                            </IconButton>
                        </Box>
                        <Typography variant='subtitle'>Pasajer@{people > 1 ? 's' :''}</Typography>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}


 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE'
})(MapContainer)

